import { createUseStyles } from 'react-jss';

export default createUseStyles(theme => ({
  testContainer: {
    padding: '22.5px',
    height: '527px',
    width: '565px',
    cursor: 'pointer',
  },
  testCard: {
    width: '100% !important',
    height: '100% !important',
    backgroundColor: theme.white,
    border: 'none',
    borderRadius: '18px',
    boxShadow: '0px 4px 4px #00000040',
    '& .otl-cardContentContainer': {
      height: '100%',
      width: '100%',
    },
    padding: '25px 21px',
    display: 'flex',
  },
  testContent: {
    backgroundColor: theme.white,
    borderRadius: '2px',
    height: '80%',
  },
  testHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '27px',
  },
  testType: {
    borderRadius: '100000px',
    padding: '7px 10px',
    fontWeight: '600',
    color: theme.testColor,
    backgroundColor: theme.testColorTransparency,
    fontSize: '12px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '60%',
  },
  exerciseTypes: {
    marginLeft: '10px',
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
  },
  exercisesNumber: {
    display: 'inline',
    whiteSpace: 'nowrap',
    '& > svg': {
      marginRight: '5px',
    },
  },
  testItems: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
  },
  exerciseItem: {
    marginBottom: '25px',
  },
  testUsages: {
    fontSize: '12px',
    fontWeight: '600',
    color: theme.testColor,
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
  },
  testUsagesIcon: {
    fontSize: '17px',
    marginRight: '5px',
  },
  testTitle: {
    marginLeft: '10px',
    fontWeight: 600,
    fontSize: '14px',
  },
  exercisesInfo: {
    display: 'flex',
    alignItems: 'baseline',
    color: theme.testColor,
    marginBottom: '20px',
  },
  useButton: {
    width: '43px',
    height: '36px',
    borderRadius: '18px 0 18px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    right: '-21px',
    marginLeft: 'auto',
    bottom: '-84px',
    backgroundColor: theme.testColor,
    '&:hover': {
      backgroundColor: theme.testColorHover,
    },
  },
  icon: {
    transform: 'scale(1.50)',
  },
  exerciseInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '75%',
  },
  exerciseInfoItem: {
    fontSize: '14px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  filters: {
    marginTop: '5px',
    marginBottom: '25px',
    maxHeight: '45%',
    overflow: 'hidden',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.25em',
  },
  innerPill: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
  },
  pillLabel: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&.hasMore': {
      maxWidth: 'calc(100% - 16px - 0.25em)',
    },
  },
  pillPlus: {
    borderRadius: '4px',
    padding: '1px 1px 1px 2px',
    color: '#e5e5e5',
    marginLeft: '0.5em',
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    testContainer: {
      width: '260px',
      height: '241px',
    },
    testItems: {
      display: 'none',
    },
    testHeader: {
      position: 'relative',
      justifyContent: 'space-between',
    },
    testTitle: {
      position: 'absolute',
      top: '40px',
      marginLeft: '0',
    },
    exercisesInfo: {
      marginTop: '40px',
      flexDirection: 'column',
    },
    exercisesNumber: {
      fontSize: '15px',
    },
    exerciseTypes: {
      maxHeight: '35px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginTop: '10px',
      fontSize: '15px',
      flexWrap: 'wrap',
    },
    useButton: {
      bottom: '-27px',
    },
  },
}));
