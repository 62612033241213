import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  exerciseItem: {
    marginTop: '25px',
  },
  sectionDescription: {
    '& > p': {
      margin: '0',
    },
  },
  section: {
    width: 'unset !important',
    '&.isExploreTest': {
      width: 'calc(100% + 20px) !important',
    },
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    section: {
      width: 'calc(100% + 20px) !important',
    },
  },
}));
