import React, { useCallback } from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import PropTypes from 'prop-types';

import useFeature from 'hooks/useFeature';
import toggles from 'toggles';

import QuillRenderer from 'components/common/QuillRenderer';

import useStyles from './styles';

const ExerciseChoice = ({ name, value, label, checked, onCheck, correct, identifier, disabled, hasMultipleCorrect }) => {
  const classes = useStyles();
  const contentIsQuill = typeof label === 'object';
  const exportIdentifiersToggle = useFeature(toggles.exportIdentifiers);

  let hasImage = false;
  if (contentIsQuill && label?.ops) {
    if (label.ops.some(op => op.insert.image)) {
      hasImage = true;
    }
  }

  const onChange = useCallback((event) => {
    if (onCheck) {
      onCheck(value);
    }
  }, [onCheck, value]);

  document.addEventListener('click', (event) => {
    if (event.target.nodeName === 'IMG') {
      event.preventDefault();
    }
  });

  const getLabel = () => {
    if (contentIsQuill) {
      return (
        <QuillRenderer
          className={cx(classes.choiceContent, { hasImage, disabled })}
          value={label}
          htmlFor={name}
        />
      );
    } else {
      return (
        <label className={cx(classes.choiceContent, { disabled })} htmlFor={name}>
          {label}
        </label>
      );
    }
  };

  return (
    <>
      <input
        type={'checkbox'}
        id={name}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        className={cx(classes.input, { disabled, hasMultipleCorrect })}
      />
      {exportIdentifiersToggle && identifier &&
        <label className={classes.identifier}>
          {identifier}
        </label>
      }
      {!(exportIdentifiersToggle && identifier) &&
        <div></div>
      }
      {getLabel()}
      {correct &&
        <FontAwesomeIcon icon={faCheck} className={classes.correctIcon} />
      }
      {!correct &&
        <div></div>
      }
    </>
  );
};

ExerciseChoice.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  checked: PropTypes.bool,
  onCheck: PropTypes.func,
  correct: PropTypes.bool,
  identifier: PropTypes.string,
  disabled: PropTypes.bool,
  hasMultipleCorrect: PropTypes.bool,
};

export default ExerciseChoice;
