import React, { Fragment, useCallback, useRef, useState } from 'react';
import { DndContext, DragOverlay, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { faCheck, faCircleCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { updateZoom } from 'actions/pageActions';
import { selectUserIsAdmin } from 'actions/userActions';
import lang from 'lang';

import ExerciseOrderItem from '../ExerciseOrderItem';
import DraggableOrderItem from 'components/exercises/exercise-form/DraggableOrderItem';
import OrderItem from 'components/exercises/exercise-form/OrderItem';

import useStyles from './styles';

const ExerciseAnswerOrdering = ({ exerciseId, answer, orderItems, isCorrection, orderingCorrect, onAnswer, answerable, isCodePreview }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isAdmin = useSelector(selectUserIsAdmin);

  const [active, setActive] = useState(null);
  const [answerOrderItems, setAnswerOrderItems] = useState(orderItems);
  const [offset, setOffset] = useState(null);

  const containerRef = useRef();

  const onActivationMouse = useCallback(({ event }) => {
    const element = event.composedPath().find(el => typeof el.className !== 'object' && el.className.includes('itemWrapper'));
    const rect = element.getBoundingClientRect();

    setOffset(event.clientY - rect.top - 32);
  }, []);

  const onActivationTouch = useCallback(({ event }) => {
    event.preventDefault();

    const x = event.touches[0].clientX;
    const y = event.touches[0].clientY;

    const path = document.elementsFromPoint(x, y);
    const element = path.find(el => typeof el.className !== 'object' && el.className.includes('itemWrapper'));

    if (element) {
      const rect = element.getBoundingClientRect();
      setOffset(event.touches[0].clientY - rect.top - 32);
    }
  }, []);

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
    onActivation: onActivationMouse,
  });
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 100,
      tolerance: 5,
    },
    onActivation: onActivationTouch,
  });
  const sensors = useSensors(mouseSensor, touchSensor);

  const handleDragEnd = useCallback((event) => {
    const { active, over } = event;
    let answerOrderItemsCopy = [...answerOrderItems];

    setActive(null);
    setOffset(null);

    if (!over) {
      return;
    }

    if (active.id !== over.id) {
      const oldIndex = answerOrderItemsCopy.map(el => el.id).indexOf(active.id);
      const newIndex = answerOrderItemsCopy.map(el => el.id).indexOf(over.id);

      answerOrderItemsCopy = arrayMove(answerOrderItemsCopy, oldIndex, newIndex);

      setAnswerOrderItems(answerOrderItemsCopy);
      if (!isCodePreview){
        onAnswer(answerOrderItemsCopy.map((item, index) => ({ order: index, ...item })));
      }
    }

    dispatch(updateZoom());
  }, [answerOrderItems, dispatch, isCodePreview, onAnswer]);

  const handleDragStart = useCallback((event) => {
    const { active } = event;
    setActive({
      item: answerOrderItems.find(el => el.id === active.id),
      index: answerOrderItems.map(el => el.id).indexOf(active.id),
    });

    if (containerRef.current) {
      return;
    }

    const rect = containerRef.current;

    rect.scrollIntoView({ behavior: 'auto', block: 'center' });
  }, [answerOrderItems]);

  return (
    <div
      ref={containerRef}
      className={cx(classes.gridContainer, { isCorrection })}
    >
      {
        !isAdmin && isCorrection ?
          <div className={cx(classes.studentOrderHeader, { isCorrect: orderingCorrect })}>
            <div className={classes.headerLabel}>
              {lang.appKeywords.answer}
            </div>
            <FontAwesomeIcon className={classes.answerIcon} icon={orderingCorrect ? faCheck : (!orderingCorrect && isCorrection) ? faTimes : null} />
          </div>
          :
          <div></div>
      }
      <div></div>
      {
        !isAdmin && isCorrection && !orderingCorrect ?
          <div className={classes.correctionOrderHeader}>
            <div className={classes.headerLabel}>
              {lang.appKeywords.correction}
            </div>
            <FontAwesomeIcon className={classes.correctionIcon} icon={faCircleCheck} />
          </div>
          :
          <div></div>
      }
      <DndContext
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        modifiers={[restrictToVerticalAxis]}
        sensors={sensors}
      >
        <SortableContext
          items={answerOrderItems}
          strategy={verticalListSortingStrategy}
        >
          {answerable ?
            (isCodePreview ? answer : answerOrderItems).map((item, index) => (
              <Fragment key={item.id}>
                <div className={cx(classes.order, { isLast: index === answerOrderItems.length - 1, isFirst: index === 0, isCorrect: orderingCorrect, isWrong: isCorrection && !orderingCorrect })}>
                  {`${index + 1}.`}
                </div>
                <DraggableOrderItem
                  id={item.id}
                  item={item}
                  index={index}
                  itemsLength={answerOrderItems.length}
                  answerable={answerable}
                  collapse={active}
                />
                {
                  isCorrection && !orderingCorrect ?
                    <div className={cx(classes.correctionOrder, { isLast: index === answerOrderItems.length - 1, isFirst: index === 0 })}>
                      {`${item.correctOrder + 1}.`}
                    </div>
                    : <div></div>
                }
              </Fragment>
            ))
            :
            orderItems.map((item, index) => (
              <>
                <div className={cx(classes.order, {
                  isLast: index === orderItems.length - 1,
                  isFirst: index === 0,
                  isCorrect: !isAdmin && (orderingCorrect || !isCorrection),
                  isWrong: !isAdmin && isCorrection && !orderingCorrect,
                })}
                >
                  {`${item.order + 1}.`}
                </div>
                <ExerciseOrderItem
                  key={item.id}
                  name={`${exerciseId}-${item.id}`}
                  identifier={item.identifier}
                  label={item.text}
                  value={item.id}
                  order={index}
                  isCorrect={item.isCorrect}
                  isCorrection={isCorrection}
                  item={item}
                />
                {
                  !isAdmin && isCorrection && !orderingCorrect ?
                    <div className={cx(classes.correctionOrder, { isLast: index === orderItems.length - 1, isFirst: index === 0 })}>
                      {`${item.correctOrder + 1}.`}
                    </div>
                    : <div></div>
                }
              </>
            ))
          }
        </SortableContext>
        <DragOverlay
          modifiers={[restrictToParentElement]}
        >
          {active ? <OrderItem item={active.item} index={active.index} answerable={answerable} collapse={active} isDragging offset={offset} /> : null}
        </DragOverlay>
      </DndContext>
    </div>
  );
};

ExerciseAnswerOrdering.propTypes = {
  exerciseId: PropTypes.string,
  answer: PropTypes.array,
  orderItems: PropTypes.array,
  orderingCorrect: PropTypes.bool,
  isCorrection: PropTypes.bool,
  onAnswer: PropTypes.func,
  answerable: PropTypes.bool,
  isCodePreview: PropTypes.bool,
};

export default ExerciseAnswerOrdering;
