import React from 'react';
import PropTypes from 'prop-types';

import lang from 'lang';

import FlowStep from '../FlowStep';
import Input from 'components/common/Input';

import useStyles from './styles';

const AskForIdentifiers = ({ number, items, setItems, type, tableDispatcher }) => {
  const classes = useStyles();

  const header = (
    <div className={classes.header}>
      {lang.exerciseForm.identifiers}
    </div>
  );

  const setIdentifier = (value, index) => {
    if (type === 'table') {
      tableDispatcher({ type: 'SET_IDENTIFIERS', payload: { value, index: items[index] } });
    } else {
      setItems(items => {
        const newChoices = [...items];

        newChoices[index].identifier = value;

        return newChoices;
      });
    }
  };

  return (
    <FlowStep
      header={header}
      stepNumber={number}
    >
      {items.map((item, index) => (
        <div
          className={classes.wrapper}
          key={item.id}
        >
          <Input
            value={item.identifier}
            onChange={(event) => setIdentifier(event.target.value, index)}
            className={classes.input}
          />
          <div className={classes.item}>
            <div
              className={classes.itemRow}
              dangerouslySetInnerHTML={{ __html: type === 'table' ? item.originalIdentifier : item.value }}
            />
          </div>
        </div>
      ))}
    </FlowStep>
  );
};

AskForIdentifiers.propTypes = {
  number: PropTypes.number,
  items: PropTypes.object,
  setItems: PropTypes.func,
  type: PropTypes.string,
  tableDispatcher: PropTypes.func,
};

export default AskForIdentifiers;
