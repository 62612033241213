import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  exerciseFormContainerOld: {
    margin: '25px 30px 0px',
  },
  exerciseFormContainer: {
    margin: '25px 100px 0px',
  },
  contentWrap: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '10px',
  },
  title: {
    fontSize: '26px',
    marginLeft: '10px',
    '&.noBack': {
      marginLeft: 'unset',
    },
  },
  modalBtnContainer: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  modalBtn: {
    marginLeft: '6px',
  },
  premiumPill: {
    marginLeft: '5px',
    marginBottom: '25px',
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    exerciseFormContainer: {
      margin: 'unset',
    },
  },
}));
