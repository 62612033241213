import React, { useMemo, useState } from 'react';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { faArrowLeft, faCopy, faPenRuler, faUser, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { useTheme } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useXarrow } from 'react-xarrows';

import { increaseUsages, selectGroups } from 'actions/exploreActions';
import { incrementTotalTests, selectUserFeatureToggleMaxUsages, selectUserTotalFreeTests } from 'actions/userActions';
import api from 'api';
import { INFORMATION, PAUSE } from 'constants/exerciseTypes';
import { PERSONAL_SPACE } from 'constants/spaces';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';
import { classificationTestTypeString, exerciseTypeString, testTypeString } from 'utils';

import ExploreSectionPreview from '../ExploreSectionPreview';
import Button from 'components/common/Button';
import Card from 'components/common/Card';
import FilterPoolItem from 'components/common/filter-selector/FilterPoolItem';
import Input from 'components/common/Input';
import UpgradePremium from 'components/common/plans/UpgradePremium';
import PreviewableExercise from 'components/exercises/PreviewableExercise';

import useStyles from './styles';

const ExploreTestPreviewModal = ({ open, close, test, copy, openCopy, dataTour }) => {
  const classes = useStyles();
  const theme = useTheme();
  const toast = useToast();
  const groups = useSelector(selectGroups);
  const dispatch = useDispatch();
  const [sendTestRequest] = useApi(api.sendTest);
  const totalFreeTests = useSelector(selectUserTotalFreeTests);
  const createTestToggle = useFeature(toggles.createTest, totalFreeTests);
  const { spaceId } = useParams();
  const createTestToggleMaxUsages = useSelector(selectUserFeatureToggleMaxUsages(toggles.createTest));
  const updateXarrow = useXarrow();

  const { testId, testName, testType, usages, classificationType, items, testAuthorName, numExercises, exercisesTypes, subjects, years } = test;

  let exerciseOrder = 0;

  const colors = {
    textColor: theme.white,
    headerColor: theme.testColor,
    bodyColor: theme.testColorTransparency,
  };

  const groupOptions = useMemo(() => groups.map(group => ({
    label: group.isPersonal ? lang.appKeywords.personalGroup : group.name,
    value: group.id,
  })), [groups]);

  const [selectedGroup, setSelectedGroup] = useState(groupOptions[0]);
  const [loading, setLoading] = useState(false);

  const copyTest = () => {
    setLoading(true);
    sendTestRequest([testId], { groupId: selectedGroup.value, copy: true, increaseUsages: true }, ({ data }) => {
      if (data.status === 0) {
        toast.success(lang.test.send.successSendTest);
        setTimeout(() => {
          if (spaceId === PERSONAL_SPACE) {
            dispatch(incrementTotalTests());
          }

          setLoading(false);
          close(true);
        }, 350);
        dispatch(increaseUsages(testId));
        return;
      }

      setLoading(false);
      toast.error(lang.oops);
      close();
    });
  };

  const getPills = (type, items) => {
    let color;

    if (!items) {
      return null;
    }
    if (type === 'subject') {
      color = '#CB6EB7';
    } else {
      color = '#F79649';
    }

    return items.map(item => (
      <FilterPoolItem key={item} poolItem={{ name: item, color }} selectable />
    ));
  };

  return (
    <Render when={open}>
      <motion.div
        animate={{ backgroundColor: '#00000033' }}
        className={classes.overlay}
        onClick={() => close(true)}
      >
        <motion.div
          layoutId={test.id}
          onClick={(event) => event.stopPropagation()}
          onLayoutAnimationComplete={updateXarrow}
          className={classes.testContainer}
        >
          <Card dataTour={dataTour} className={classes.testCard}>
            <Button
              dataTour={`${dataTour}-copy-button`}
              className={classes.useButton}
              onClick={copy.state ? () => close(false) : openCopy}
            >
              <FontAwesomeIcon icon={copy.state ? faArrowLeft : faCopy} className={classes.icon} />
              {
                !copy.state && lang.copy
              }
            </Button>
            <div
              className={classes.closeButton}
              onClick={close}
              role="button"
              aria-label="close-preview"
            >
              <FontAwesomeIcon
                icon={faCircleXmark}
                className={classes.closeButtonIcon}
              />
            </div>
            <Render when={createTestToggle || !copy.state}>
              <div className={classes.exerciseContent}>
                <motion.div className={classes.exerciseTitle}>
                  {testName}
                </motion.div>
                <div className={classes.exerciseContentInfo}>

                  <motion.div className={classes.testType}>
                    <span
                      className={classes.testTypeText}
                      title={classificationTestTypeString(classificationType)}
                    >
                      {testTypeString(testType)}
                      <span> &#8226; </span>
                      {classificationTestTypeString(classificationType)}
                    </span>
                  </motion.div>
                  <div className={classes.exerciseAuthor}>
                    <FontAwesomeIcon
                      icon={faUser}
                      className={classes.exerciseUsagesIcon}
                    />
                    {testAuthorName}
                  </div>
                </div>
                <div className={classes.filters}>
                  {getPills('subject', subjects)}
                  {getPills('year', years)}
                </div>
                <div className={classes.exercisesInfo}>
                  <motion.div className={classes.exerciseUsages}>
                    <FontAwesomeIcon
                      icon={faUserGroup}
                      className={classes.exerciseUsagesIcon}
                    />
                    {usages}
                    {' '}
                    {lang.copies}
                  </motion.div>
                  <div className={classes.exercisesNumber}>
                    <FontAwesomeIcon
                      icon={faPenRuler}
                      className={classes.exercisesNumberIcon}
                    />
                    {numExercises}
                    {' '}
                    {lang.tests.teacher.exercises}
                    {':'}
                  </div>
                  <Render when={exercisesTypes}>
                    <div className={classes.exerciseTypes}>
                      {exercisesTypes?.map((type) => (
                        <span key={type}>
                          {exerciseTypeString(type)}
                          {exercisesTypes.length - 1 !== exercisesTypes.indexOf(type) ? '; ' : null}
                        </span>
                      ))}
                    </div>
                  </Render>
                </div>
                <Render when={!copy.state}>
                  <div className={classes.testExercises}>
                    {items.map((item) => {
                      if (item.exerciseId) {
                        if (![INFORMATION, PAUSE].includes(item.type)) {
                          exerciseOrder = exerciseOrder += 1;
                        }
                        return (
                          <PreviewableExercise
                            key={item.id}
                            {...item}
                            colors={colors}
                            header={![INFORMATION, PAUSE].includes(item.type) ? lang.test.question + ' ' + (exerciseOrder) : lang.test.informationBlock}
                          />
                        );
                      } else if (item.testId) {
                        return (
                          <ExploreSectionPreview
                            key={item.id}
                            {...item}
                            colors={colors}
                          />
                        );
                      }
                      return null;
                    })}
                  </div>
                </Render>
                <Render when={copy.state}>
                  <div data-tour={`${dataTour}-select-group-container`} className={classes.copyContainer}>
                    <div className={classes.copyInformation}>
                      {lang.explore.copyInformation}
                    </div>
                    <Input
                      type="select"
                      options={groupOptions}
                      value={selectedGroup}
                      onChange={(value) => setSelectedGroup(value)}
                    />
                  </div>
                </Render>
              </div>
              <Render when={copy.state}>
                <div className={classes.copyActions}>
                  <Button
                    className={classes.copyAction}
                    onClick={() => close()}
                    black
                  >
                    {lang.cancel}
                  </Button>
                  <Button
                    dataTour={`${dataTour}-select-group-submit`}
                    className={classes.copyAction}
                    loading={loading}
                    onClick={copyTest}
                  >
                    {lang.confirm}
                  </Button>
                </div>
              </Render>
            </Render>
            <Render when={!createTestToggle && copy.state}>
              <UpgradePremium
                message={lang.plans.exceededTests(createTestToggleMaxUsages)}
              />
            </Render>
          </Card>
        </motion.div>
      </motion.div>
    </Render>
  );
};

ExploreTestPreviewModal.propTypes = {
  open: PropTypes.object,
  close: PropTypes.func,
  test: PropTypes.object,
  copy: PropTypes.bool,
  openCopy: PropTypes.func,
  dataTour: PropTypes.string,
};

export default ExploreTestPreviewModal;
