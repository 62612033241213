import { createUseStyles } from 'react-jss';

export default createUseStyles({
  attemptContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'center',
  },
});
