import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  exerciseContainer: {
    width: '1120px !important',
    '& > .otl-cardContentContainer': {
      padding: '0px',
    },
    borderRadius: '18px',
    overflow: 'visible',
    border: `1px solid ${theme.paginationBorder}`,
    boxShadow: '0 4px 4px #00000040',
    '&.noCard': {
      border: 'none',
      backgroundColor: 'unset',
      boxShadow: 'unset',
    },
    '&.colors': {
      boxShadow: 'unset',
      border: 'none',
    },
  },
  [`@media (max-width: ${theme.xl}px)`]: {
    exerciseContainer: {
      width: '920px !important',
    },
  },
  [`@media (max-width: ${theme.lg}px)`]: {
    exerciseContainer: {
      width: '710px !important',
    },
  },
  [`@media (max-width: ${theme.md}px)`]: {
    exerciseContainer: {
      width: '490px !important',
    },
  },
  [`@media (max-width: ${theme.sm}px)`]: {
    exerciseContainer: {
      width: '530px !important',
    },
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    exerciseContainer: {
      width: '100% !important',
    },
  },
}));
