import { INCREMENTAL } from 'constants/presentationTypes';
import { deepCopy } from 'utils';

const setAttemptSectionItems = (attemptItems, sectionId, sectionItems) => {
  const newAttemptItems = deepCopy(attemptItems);

  const section = newAttemptItems.find(item => item.id === sectionId);

  if (!section) {
    return attemptItems;
  }

  section.exercises = sectionItems;

  if (section.presentation === INCREMENTAL) {
    section.isLastSectionItem = section.exercises[0].isLast;
    section.isFirstSectionItem = section.exercises[0].order === 0;
    section.currentItem = section.exercises[0].order;
  }

  return newAttemptItems;
};

export default setAttemptSectionItems;
