import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  captionCard: {
    minHeight: '37px',
    borderRadius: '4px',
    backgroundColor: '#e7e7e7',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    position: 'relative',
    gap: '0.25em',
    border: '1px solid #d8d8d8',
    maxWidth: '105px',
    padding: '2px 4px',
  },
  icon: {
    cursor: 'pointer',
    opacity: '0.6',
    '&:hover': {
      opacity: '1',
    },
  },
  identifier: {
    position: 'absolute',
    top: '-75%',
    backgroundColor: `${theme.buttonBackground}B0`,
    padding: '0.15em',
    borderRadius: '6px',
    zIndex: '5',
    '&:hover': {
      backgroundColor: `${theme.buttonBackground}00`,
      color: `${theme.buttonBackground}00`,
    },
  },
}));
