import React from 'react';
import { Spacer } from '@intuitivo/outline';
import cx from 'classnames';
import PropTypes from 'prop-types';

import useFeature from 'hooks/useFeature';
import toggles from 'toggles';
import { quillIsEmpty } from 'utils';

import EntityBody from '../../common/entity/EntityBody';
import EntityContent from '../../common/entity/EntityContent';
import ExerciseJustification from '../exercise/exercise-answer/ExerciseJustification';
import ExerciseModelAnswer from '../exercise/ExerciseModelAnswer';
import ExerciseStatement from '../exercise/ExerciseStatement';
import ProcessNodeOptions from '../exercise-form/ProcessNodeOptions';
import EntityExpandableText from 'components/common/entity/EntityExpandableText';
import EntityHeader from 'components/common/entity/EntityHeader';
import ExerciseAnswer from 'components/exercises/exercise/exercise-answer/ExerciseAnswer';

import useStyles from './styles';

const PreviewableExercise = ({ id, statement, type, choices, exerciseCells, gaps, orderItems, correctTrueFalse, noCard, hasJustification, image, header, colors, isShortAnswer, isExploreTest, option, extraText, extraTextStartExpanded, shuffleChoices, connectors, connections, enableMathSymbols, modelAnswer, gradingOption, hasWordCount, isFullWidth, answer, isCodePreview }) => {
  const classes = useStyles();
  const modelAnswerToggle = useFeature(toggles.modelAnswer);

  const hasMultipleCorrectChoices = choices?.filter(choice => choice.isCorrect).length > 1;
  const processNode = (node) => {
    const drop = gaps.find(el => (el.isCorrect && el.position === node.attribs['data-position']));

    if (!drop) {
      return null;
    }

    if (answer) {
      const gapAnswer = answer?.find(el => el?.gapId === drop.id);
      const item = gaps.find(gap => gap.id === gapAnswer?.gapId);

      return (
        <ProcessNodeOptions
          drop={drop}
          gaps={gaps}
          option={option}
          item={item}
          dropAnswers={answer}
          enableMathSymbols={enableMathSymbols}
          answerable
        />
      );
    } else {
      return (
        <ProcessNodeOptions
          key={drop.id}
          id={id}
          drop={drop}
          gaps={gaps}
          option={option}
          enableMathSymbols={enableMathSymbols}
          preview
        />
      );
    }
  };

  const instructions = [
    {
      shouldProcessNode: (node) => {
        return node.attribs && node.attribs['data-drop'];
      },
      processNode: processNode,
    },
  ];

  return (
    <EntityBody colors={colors} noCard={noCard} className={cx(classes.exercise, { isFullWidth })}>
      {header && (
        <EntityHeader
          colors={colors}
        >
          {header}
        </EntityHeader>
      )}
      <EntityContent>
        <ExerciseStatement
          statement={statement}
          instructions={instructions}
        />
        {!isExploreTest && (
          <>
            <ExerciseAnswer
              answer={answer}
              type={type}
              choices={choices}
              exerciseCells={exerciseCells}
              shuffleChoices={shuffleChoices}
              gaps={gaps}
              orderItems={orderItems}
              correctTrueFalse={correctTrueFalse}
              image={image}
              dropAnswers={gaps}
              color={colors}
              hasMultipleCorrectChoices={hasMultipleCorrectChoices}
              isShortAnswer={isShortAnswer}
              option={option}
              connectors={connectors}
              connections={connections}
              enableMathSymbols={enableMathSymbols}
              gradingOption={gradingOption}
              preview={!answer}
              answerable={!!answer}
              hasWordCount={hasWordCount}
              isCodePreview={isCodePreview}
            />
            {hasJustification && (
              <ExerciseJustification
                color={colors}
                enableMathSymbols={enableMathSymbols}
                hasWordCount={hasWordCount}
              />
            )}
            {!quillIsEmpty(extraText) && (
              <>
                <Spacer px={10} />
                <EntityExpandableText
                  text={extraText}
                  full={extraTextStartExpanded}
                />
              </>
            )}
            {modelAnswerToggle && !quillIsEmpty(modelAnswer) && (
              <>
                <Spacer px={10} />
                <ExerciseModelAnswer
                  modelAnswer={modelAnswer}
                />
              </>
            )}
          </>
        )}
      </EntityContent>
    </EntityBody>
  );
};

PreviewableExercise.propTypes = {
  id: PropTypes.string,
  statement: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  choices: PropTypes.arrayOf(PropTypes.object),
  exerciseCells: PropTypes.arrayOf(PropTypes.object),
  gaps: PropTypes.arrayOf(PropTypes.object),
  orderItems: PropTypes.arrayOf(PropTypes.object),
  correctTrueFalse: PropTypes.bool,
  noCard: PropTypes.bool,
  hasJustification: PropTypes.bool,
  image: PropTypes.string,
  colors: PropTypes.object,
  header: PropTypes.string,
  isExploreTest: PropTypes.bool,
  isShortAnswer: PropTypes.bool,
  option: PropTypes.string,
  extraText: PropTypes.object,
  extraTextStartExpanded: PropTypes.bool,
  shuffleChoices: PropTypes.bool,
  connectors: PropTypes.array,
  connections: PropTypes.array,
  enableMathSymbols: PropTypes.bool,
  modelAnswer: PropTypes.object,
  gradingOption: PropTypes.string,
  hasWordCount: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  answer: PropTypes.any,
  isCodePreview: PropTypes.bool,
};

export default PreviewableExercise;
