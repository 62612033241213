import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  choicesContainer: {
    padding: '15px',
  },
  choiceRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  checkBtn: {
    fontSize: '30px',
    marginRight: '10px',
    cursor: 'pointer',
    color: theme.unselectedColor2,
    '&:hover': {
      color: theme.unselectedHover2,
    },
    '&.active': {
      color: theme.selectedColor2,
      '&:hover': {
        color: theme.selectedHover2,
      },
    },
  },
  deleteBtn: {
    fontSize: '20px',
    marginLeft: '10px',
    cursor: 'pointer',
    color: theme.removeColor,
    '&.disabled': {
      color: theme.unselectedColor2,
      cursor: 'not-allowed',
    },
  },
  newChoiceText: {
    marginLeft: '10px',
  },
  optionInput: {
    '& .ql-toolbar': {
      padding: '0',
      border: `1px solid ${theme.cardBorder3}`,
    },
    '& .ql-container': {
      border: `1px solid ${theme.cardBorder3}`,
    },
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  toggleWrapper: {
    display: 'flex',
    gap: '0.35em',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: '300 !important',
  },
  optionsWrapper: {
    display: 'flex',
  },
  identifier: {
    color: theme.textColor5,
    marginLeft: '5px',
    fontSize: '14px',
    whiteSpace: 'nowrap',
  },
}));
