import React, { Fragment, useCallback, useMemo, useRef } from 'react';
import { faArrowLeft, faArrowRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserIsAdmin } from 'actions/userActions';
import { LOCAL } from 'constants/environments';
import { DRAG_DROP, DROPDOWN, WRITE } from 'constants/exerciseOptions';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';
import { deepCopy } from 'utils';

import DraggableGap from '../exercise-answer-filling/DraggableGap';
import Drop from '../exercise-answer-filling/Drop';
import InnerGap from '../exercise-answer-filling/InnerGap';
import Input from 'components/common/Input';

import useStyles from './styles';

const ExerciseAnswerCaption = ({ image, gaps, dropAnswers, answerable, correction, preview, option, setDropAnswers, onAnswer, setWriting }) => {
  const classes = useStyles();
  const timeoutRef = useRef();
  const maxImageSize = 700;
  const identifiersToggle = useFeature(toggles.exportIdentifiers);
  const asyncAnswersToggle = useFeature(toggles.asyncAnswers);
  const isAdmin = useSelector(selectUserIsAdmin);

  const finalSrc = useMemo(() => {
    let finalSrc = image;
    if (finalSrc && process.env.REACT_APP_NODE_ENV === LOCAL) {
      const hostname = window.location.hostname;
      finalSrc = finalSrc.replace('localhost', hostname);
    }

    return finalSrc;
  }, [image]);

  const getOptions = (drop) => {
    return gaps
      .filter(gap => gap.gapCoords.x === drop.gapCoords.x && gap.gapCoords.y === drop.gapCoords.y)
      .sort((a, b) => a.order - b.order)
      .map(gap => ({
        value: gap.id,
        label: (
          <div className={classes.optionWrapper}>
            {gap.text}
            {!answerable && gap.identifier && identifiersToggle &&
              <div className={classes.identifier}>
                (
                {gap.identifier}
                )
              </div>
            }
            {(preview || correction) && gap.isCorrect && !isAdmin &&
              <FontAwesomeIcon
                className={classes.check}
                icon={faCheck}
              />
            }
          </div>
        ),
        isDisabled: !answerable,
      }));
  };

  const onChange = useCallback((event, drop) => {
    const newDropAnswers = deepCopy(dropAnswers);

    const newDrop = newDropAnswers.find(gap => gap.id === drop.id);

    newDrop.gapId = event.value;

    setDropAnswers(newDropAnswers);
    onAnswer(newDropAnswers);
  }, [dropAnswers, setDropAnswers, onAnswer]);

  const onChangeWrite = useCallback((event, drop) => {

    const newDropAnswers = deepCopy(dropAnswers);
    const newDrop = newDropAnswers.find(gap => gap.id === drop.id);
    newDrop.text = event.target.value;
    setDropAnswers(newDropAnswers);

    if (asyncAnswersToggle) {
      onAnswer(newDropAnswers);
      return;
    }

    setWriting(true);

    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      onAnswer(newDropAnswers);
      setWriting(false);
    }, 2000);

  }, [dropAnswers, setDropAnswers, onAnswer, setWriting, asyncAnswersToggle]);

  const onBlur = useCallback((event, drop) => {
    setWriting(false);
    const newDropAnswers = deepCopy(dropAnswers);

    const newDrop = newDropAnswers.find(gap => gap.id === drop.id);

    newDrop.text = event.target.value;
    clearTimeout(timeoutRef.current);
    setDropAnswers(newDropAnswers);
    onAnswer(newDropAnswers);
  }, [dropAnswers, setDropAnswers, onAnswer, setWriting]);

  const createCaptionElement = (drop, x, y, length, angle, gapCoordsX, gapCoordsY, pointCoordsX, pointCoordsY) => {

    const style = {
      border: '1px solid black',
      width: length + 'px',
      height: '0px',
      transform: 'rotate(' + angle + 'rad)',
      position: 'absolute',
      top: y + 'px',
      left: x + 'px',
      backgroundColor: 'black',
    };

    const pointStyle = {
      backgroundColor: 'black',
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      position: 'absolute',
      top: pointCoordsY - 4 + 'px',
      left: pointCoordsX - 4 + 'px',
    };

    const gapStyle = {
      position: 'absolute',
      height: '39px',
      top: gapCoordsY - 19 + 'px',
      left: gapCoordsX - 25 + 'px',
    };

    const correctionItem = gaps.find(gap => gap.isCorrect && gap.gapCoords && gap.gapCoords.x === drop.gapCoords.x && gap.gapCoords.y === drop.gapCoords.y);
    const answerItem = gaps.find(gap => gap.answer && gap.gapCoords && gap.gapCoords.x === drop.gapCoords.x && gap.gapCoords.y === drop.gapCoords.y);
    const isCorrectWrite = drop?.text === correctionItem?.text;

    return (
      <Fragment key={drop.id}>
        <div style={style} />
        <div style={pointStyle} />
        <div style={gapStyle}>
          {(!option || option === DRAG_DROP) &&
            <Drop
              item={preview ? { ...drop, isCorrectAnswer: true } : gaps.find(gap => gap.id === drop.gapId)}
              drop={drop}
              answerable={answerable}
              correction={(correction || preview) && !isAdmin}
              correctionItem={correction ? correctionItem : null}
            />
          }
          {option === DROPDOWN ?
            <Input
              type="select"
              placeholder={
                correction ?
                  <Drop
                    item={gaps.find(gap => gap.id === drop.gapId)}
                    drop={drop}
                    correction={correction && !isAdmin}
                    correctionItem={correction ? correctionItem : null}
                  />
                  :
                  preview ?
                    gaps.find(gap => gap.isCorrect && gap.gapCoords && gap.gapCoords.x === drop.gapCoords.x && gap.gapCoords.y === drop.gapCoords.y).text
                    :
                    gaps.find(gap => gap.id === drop.gapId)?.text
              }
              options={getOptions(drop)}
              className={cx(classes.inputDropdown, { isCorrect: preview ?? (answerItem?.isCorrectAnswer && !isAdmin), answerable: answerable, correction: correction, isAdmin })}
              onChange={(event) => onChange(event, drop)}
            />
            :
            option === WRITE ?
              <>
                {!correction &&
                  <Input
                    type="text"
                    value={drop.text}
                    className={cx(classes.inputWrite, { isCorrect: preview || (!answerable && isCorrectWrite), answerable: answerable })}
                    onChange={(event) => onChangeWrite(event, drop)}
                    onBlur={(event) => onBlur(event, drop)}
                    disabled={!answerable}
                    inline
                  />
                }
                {correction &&
                  <Drop
                    item={drop}
                    drop={drop}
                    correction={correction && !isAdmin}
                    correctionItem={correction ? correctionItem : null}
                  />
                }
              </>
              :
              null
          }
        </div>
      </Fragment>
    );
  };

  const createCaption = (drop) => {
    const { gapCoords, pointCoords } = drop;
    if (!gapCoords && !pointCoords) {
      return;
    }

    const x1 = (gapCoords.x / 100) * maxImageSize, y1 = (gapCoords.y / 100) * maxImageSize;

    const x2 = (pointCoords.x / 100) * maxImageSize, y2 = (pointCoords.y / 100) * maxImageSize;

    const a = x1 - x2,
      b = y1 - y2,
      c = Math.sqrt(a * a + b * b);

    const sx = (x1 + x2) / 2,
      sy = (y1 + y2) / 2;

    const x = sx - c / 2,
      y = sy;

    const alpha = Math.PI - Math.atan2(-b, a);

    return createCaptionElement(drop, x, y, c, alpha, x1, y1, x2, y2);
  };

  const getCaptions = () => {
    return dropAnswers.map(drop => createCaption(drop));
  };

  return (
    image &&
      <>
        <div className={classes.wrapper}>
          <div className={classes.imageWrapper} >
            <img src={finalSrc} className={classes.image} alt={'caption'} />
            {getCaptions()}
          </div>
        </div>
        <div className={classes.scrollHint}>
          <FontAwesomeIcon
            icon={faArrowLeft}
          />
          <span className={classes.scrollHintLabel}>
            {lang.scrollToSides}
          </span>
          <FontAwesomeIcon
            icon={faArrowRight}
          />
        </div>
        {(!option || option === DRAG_DROP) &&
        <>
          {correction ? lang.exercises.gapListUnused : lang.exercises.gapList}
          <div className={classes.gapList}>
            {answerable ?
              gaps
                .filter(gap => !dropAnswers.some(elem => elem.gapId === gap.id))
                .map((gap) => (
                  <DraggableGap
                    key={gap.id}
                    item={gap}
                    draggable={answerable}
                  />
                ))
              :
              gaps
                .filter(gap => !dropAnswers.some(elem => elem.gapId === gap.id))
                .map((gap) => (
                  <InnerGap
                    key={gap.id}
                    item={correction ? gap : { ...gap, isCorrect: null }}
                    draggable={answerable}
                    identifier={gap.identifier}
                  />
                ))
            }
          </div>
        </>
        }
      </>
  );
};

ExerciseAnswerCaption.propTypes = {
  image: PropTypes.string,
  gaps: PropTypes.array,
  dropAnswers: PropTypes.array,
  setDropAnswers: PropTypes.func,
  onAnswer: PropTypes.func,
  answerable: PropTypes.bool,
  correction: PropTypes.bool,
  preview: PropTypes.bool,
  option: PropTypes.string,
  setWriting: PropTypes.func,
};

export default ExerciseAnswerCaption;
