export const highlightColors = [
  {
    name: 'red',
    'default': '#fa6d6d',
    overlap: '#f21818',
  },
  {
    name: 'yellow',
    'default': '#f5eb1e',
    overlap: '#cdc409',
  },
  {
    name: 'green',
    'default': '#25cf45',
    overlap: '#1da437',
  },
  {
    name: 'blue',
    'default': '#51c1ff',
    overlap: '#0094e5',
  },
];
