import React from 'react';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';

import lang from 'lang';
import { quillIsEmpty } from 'utils';

import FlowStep from '../FlowStep';
import PreviewableExercise from 'components/exercises/PreviewableExercise';

import useStyles from './styles';

const PreviewExercise = ({ statement, type, choices, gaps, exerciseCells, orderItems, correctTrueFalse, hasJustification, setHasJustification, isShortAnswer, image, option, extraText, extraTextStartExpanded, connectors, connections, enableMathSymbols, modelAnswer, gradingOption, hasWordCount }) => {
  const classes = useStyles();

  const getStatement = () => {
    if (!quillIsEmpty(statement)) {
      return statement;
    }

    return {
      ops: [
        {
          insert: lang.exerciseForm.statementPlaceholder,
        },
      ],
    };
  };

  const getChoices = () => {
    if (choices) {
      return choices.map((choice, index) => ({
        ...choice,
        value: !quillIsEmpty(choice.value) ? choice.value : {
          ops: [
            {
              insert: lang.exerciseForm.option + ` ${index + 1}`,
            },
          ],
        },
      }));
    }
  };

  return (
    <FlowStep
      dataTour="preview-exercise"
      header={lang.exercises.previewExercise}
    >
      <div className={classes.previewExerciseContainer}>
        <PreviewableExercise
          id={v4()}
          statement={getStatement()}
          type={type}
          choices={getChoices()}
          gaps={gaps ? gaps : []}
          exerciseCells={exerciseCells}
          orderItems={orderItems ?? []}
          correctTrueFalse={correctTrueFalse}
          noCard
          hasJustification={hasJustification}
          setHasJustification={setHasJustification}
          image={image}
          isShortAnswer={isShortAnswer}
          option={option}
          extraText={extraText}
          extraTextStartExpanded={extraTextStartExpanded}
          connectors={connectors}
          connections={connections}
          enableMathSymbols={enableMathSymbols}
          modelAnswer={modelAnswer}
          gradingOption={gradingOption}
          hasWordCount={hasWordCount}
          isFullWidth
        />
      </div>
    </FlowStep>
  );
};

PreviewExercise.propTypes = {
  statement: PropTypes.object,
  type: PropTypes.string,
  gaps: PropTypes.array,
  exerciseCells: PropTypes.array,
  orderItems: PropTypes.array,
  choices: PropTypes.array,
  correctTrueFalse: PropTypes.bool,
  hasJustification: PropTypes.bool,
  setHasJustification: PropTypes.func,
  image: PropTypes.array,
  isShortAnswer: PropTypes.bool,
  option: PropTypes.string,
  extraText: PropTypes.object,
  extraTextStartExpanded: PropTypes.bool,
  connectors: PropTypes.array,
  connections: PropTypes.array,
  enableMathSymbols: PropTypes.bool,
  modelAnswer: PropTypes.object,
  gradingOption: PropTypes.string,
  hasWordCount: PropTypes.bool,
};

export default PreviewExercise;
