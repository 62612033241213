import { createUseStyles } from 'react-jss';

export default createUseStyles({
  exerciseLabelsOld: {
    margin: '20px 35px',
    gap: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
  exerciseLabels: {
    margin: '20px 100px',
    gap: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
  premiumPill: {
    width: 'fit-content',
  },
});
