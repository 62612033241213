import React from 'react';
import PropTypes from 'prop-types';

import HeaderCell from '../HeaderCell';
import ExerciseChoice from 'components/exercises/exercise/exercise-answer/ExerciseChoice';

import useStyles from './styles';

const TableCell = ({ col, row, onClick, ...restProps }) => {
  const classes = useStyles();

  let childNode = restProps.children;
  if (col === 0 || col === -1) {
    childNode = (
      <HeaderCell
        col={col}
        row={row}
        restricted={restProps.restricted}
        {...restProps}
      />
    );
  } else {
    if (restProps.isButton){
      childNode = (
        <td {...restProps} onClick={onClick} >
          {restProps.children}
        </td>
      );
    } else {
      childNode = (
        <td {...restProps}
          onClick={() => onClick(row, col)} className={classes.exerciseCell}
        >
          <div className={classes.exerciseChoiceWrapper}>
            <ExerciseChoice
              name={`${row}-${col}`}
              hasMultipleCorrect
              checked={restProps.isCorrect}
            />
          </div>
          <div>
            {restProps.exportIdentifiersToggle &&
              <div className={classes.identifier}>
                {restProps.identifier}
              </div>
            }
          </div>
        </td>
      );
    }
  }

  return childNode;
};

TableCell.propTypes = {
  col: PropTypes.number.isRequired,
  row: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default TableCell;
