import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import useStyles from './styles';

const EntityInformation = ({ informations }) => {
  const classes = useStyles();

  return (
    <div className={classes.testInfoWrap}>
      {informations
        .filter((info) => !info.hide)
        .map((info, index) => (
          <div key={info.label} className={classes.info}>
            <div className={classes.iconContainer}>
              <FontAwesomeIcon
                icon={info.icon}
              />
            </div>
            <div className={classes.testInfo}>
              {info.label}
            </div>
            {index !== informations.filter(el => !el.hide).length - 1 && (
              <div className={classes.testInfoSeparator}>
                •
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

EntityInformation.propTypes = {
  informations: PropTypes.array,
};

export default EntityInformation;
