import React, { useState, useCallback } from 'react';
import { faCheck, faClipboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from '@intuitivo/outline';
import { Icon, useToast } from '@intuitivo-pt/outline-ui';
import { Button, Dropdown } from 'antd';
import cx from 'classnames';
import { ReactComponent as InvalidIcon } from 'icons/invalid-icon.svg';
import { ReactComponent as PendingIcon } from 'icons/pending-icon.svg';
import { ReactComponent as ValidIcon } from 'icons/valid-icon.svg';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserIsAdmin, selectUserLang } from 'actions/userActions';
import api from 'api';
import { VALID, INVALID, PENDING } from 'constants/attemptValidation';
import useApi from 'hooks/common/useApi';
import lang from 'lang';
import routes from 'routes';

import Card from 'components/common/Card';
import Tooltip from 'components/common/Tooltip';

import useStyles from './styles';

const StudentAttemptHeader = ({ attempt, classificationType, student, isStudent, grade, setStudents }) => {
  const classes = useStyles();
  const isAdmin = useSelector(selectUserIsAdmin);
  const locale = useSelector(selectUserLang);

  const actions = [
    {
      key: VALID,
      label: lang.attempt.statusValid,
      icon: <ValidIcon className={classes.validationIcon} />,
    },
    {
      key: INVALID,
      label: lang.attempt.statusInvalid,
      icon: <InvalidIcon className={classes.validationIcon} />,
    },
    {
      key: PENDING,
      label: lang.attempt.statusPending,
      icon: <PendingIcon className={classes.validationIcon} />,
    },
  ];

  const localeMap = {
    en: 'en',
    pt: 'pt',
    esES: 'es-ES',
    ptBR: 'pt-BR',
  };

  const [validationStatus, setValidationStatus] = useState(student?.validation);

  const [clipboarded, setClipboarded] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [updateStudentValidationRequest] = useApi(api.updateAttemptValidation);
  const toast = useToast();

  const startedAtHumanString = new Date(attempt.startedAt).toLocaleTimeString(localeMap[locale], { hour: '2-digit', minute: '2-digit' });
  const endedAtHumanString = new Date(attempt.endedAt).toLocaleTimeString(localeMap[locale], { hour: '2-digit', minute: '2-digit' });

  const updateStudentValidation = useCallback((key) => {

    updateStudentValidationRequest([student.attemptId], { validation: key }, ({ data }) => {
      if (data.status === 0) {
        toast.success(lang.attempt.statusUpdated);
        setValidationStatus(key);
        setStudents((students) => students.map((s) => (s.attemptId === student.attemptId ? { ...s, validation: key } : s)));
      }
    });
  }, [setStudents, student, toast, updateStudentValidationRequest]);

  const copyToClipboard = (link) => {
    if (!attempt.id) {
      return;
    }

    navigator.clipboard.writeText(link);
    setClipboarded(true);
    setTimeout(() => {
      setClipboarded(false);
    }, 1000);
  };

  const getSubmissionLinkHeader = () => (
    <div className={classes.linkHeader}>
      {lang.test.grades.submissionLink}
      <Tooltip tip={clipboarded ? lang.copied : lang.copy} place={'bottom'}>
        <FontAwesomeIcon
          icon={clipboarded ? faCheck : faClipboard}
          className={cx(classes.clipboard, { selected: clipboarded, disabled: !attempt.id })}
          onClick={() => copyToClipboard(`${routes.testsApp.ref()}${routes.attempt.ref(attempt.id)}`)}
        />
      </Tooltip>
    </div>
  );

  const getCriteriaTotals = () => {
    if (!attempt.id || classificationType !== 'rubric' || !attempt.criteriaTotals) {
      return;
    }

    let criteriaTotals = '';

    attempt.criteriaTotals.forEach((criteria, index) => {
      criteriaTotals += `${criteria.name} (${Math.round(parseFloat(criteria.grade))}/5)${index === attempt.criteriaTotals.length - 1 ? '' : ', '}`;
    });

    return criteriaTotals;
  };

  if (!attempt) {
    return null;
  }

  const onItemClick = ({ key, domEvent }) => {
    domEvent.stopPropagation();
    setValidationStatus(key);
    updateStudentValidation(key);
    setDropdownOpen(false);
  };

  const infoSections = [
    {
      header: lang.name,
      value: attempt.student.name,
    },
    {
      header: lang.email,
      value: attempt.student.email ? attempt.student.email : lang.noEmail,
      hide: isStudent || isAdmin,
    },
    {
      header: lang.appKeywords.grade,
      value: grade,
      hide: classificationType === 'none' || isAdmin,
    },
    {
      header: getSubmissionLinkHeader(),
      value: attempt.id ? `${routes.testsApp.ref()}${routes.attempt.ref(attempt.id)}` : lang.test.grades.noAttempt,
      hide: isAdmin,
    },
    {
      header: lang.appKeywords.rubric,
      value: getCriteriaTotals(),
      hide: classificationType !== 'rubric' || isAdmin,
    },
    {
      header: lang.test.grades.attemptDates,
      value: `${startedAtHumanString}h - ${attempt.endedAt ? endedAtHumanString + 'h' : lang.test.grades.notFinished}`,
      hide: !isAdmin,
    },
    {
      header: lang.attempt.status,
      value:
      (
        <Dropdown
          menu={{ items: actions, onClick: onItemClick }}
          trigger="click"
          onOpenChange={(open) => setDropdownOpen(open)}
        >
          <Button className={classes.dropdownButton}>
            <div className={classes.dropdownWrapper}>
              {actions.find(action => action.key === validationStatus)?.icon}
              {actions.find(action => action.key === validationStatus)?.label}
            </div>
            {dropdownOpen ? <Icon icon="chevron-up" className={classes.dropdownIcon} /> : <Icon icon="chevron-down" className={classes.dropdownIcon} />}
          </Button>
        </Dropdown>
      ),
      hide: !isAdmin,
    },
  ];

  if (isAdmin) {
    return (
      <Card className={classes.adminAttemptHeader}>
        <div className={classes.adminInfoContainer}>
          {infoSections.map(
            (section) =>
              !section.hide && (
                <div key={section.header} className={classes.adminInfoSection}>
                  <div className={classes.adminInfoHeader}>
                    <div className={classes.adminInfoHeaderSection}>
                      {section.header}
                      {': '}
                    </div>
                    <div className={classes.adminInfoValue}>
                      {section.value}
                    </div>
                  </div>
                </div>
              ),
          )}
        </div>
      </Card>
    );
  } else {
    return (
      <Card
        dataTour="student-attempt-header"
        header={lang.test.grades.submission}
        className={classes.attemptHeader}
      >
        <Row className={classes.attemptInfoContainer}>
          {infoSections.map(section => (
            !section.hide && (
              <Col
                xl={6}
                key={section.header}
              >
                <div className={classes.infoSection}>
                  <div className={classes.infoHeader}>
                    {section.header}
                  </div>
                  <div>
                    {section.value}
                  </div>
                </div>
              </Col>
            )
          ))}
        </Row>
      </Card>
    );
  }
};

StudentAttemptHeader.propTypes = {
  attempt: PropTypes.object,
  grade: PropTypes.string,
  classificationType: PropTypes.string,
  isStudent: PropTypes.bool,
  student: PropTypes.object,
  setStudents: PropTypes.func,
};

export default StudentAttemptHeader;
