import React from 'react';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import lang from 'lang';
import { quillIsEmpty } from 'utils';

import EntityBody from 'components/common/entity/EntityBody';
import EntityContent from 'components/common/entity/EntityContent';
import EntityHeader from 'components/common/entity/EntityHeader';
import EntitySubHeader from 'components/common/entity/EntitySubHeader';
import QuillRenderer from 'components/common/QuillRenderer';
import PreviewableExercise from 'components/exercises/PreviewableExercise';

import useStyles from './styles';

const ExploreSectionPreview = ({ name, description, items, colors, isExploreTest }) => {
  const classes = useStyles();

  let exerciseOrder = 0;

  return (
    <EntityBody colors={colors} className={cx(classes.section, { isExploreTest })}>
      <EntityHeader
        colors={colors}
      >
        {name}
      </EntityHeader>
      <Render when={!quillIsEmpty(description)}>
        <EntitySubHeader
          colors={colors}
        >
          <QuillRenderer
            value={description}
            className={classes.sectionDescription}
          />
        </EntitySubHeader>
      </Render>
      <EntityContent>
        <Render when={!isExploreTest}>
          {items.map((item) => {
            if (item.type !== 'information') {
              exerciseOrder = exerciseOrder += 1;
            }

            return (
              <PreviewableExercise
                key={item.id}
                {...item}
                colors={colors}
                header={item.type !== 'information' ? lang.test.question + ' ' + (exerciseOrder) : lang.test.informationBlock}
              />
            );
          })}
        </Render>
      </EntityContent>
    </EntityBody>
  );
};

ExploreSectionPreview.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  colors: PropTypes.object,
  isExploreTest: PropTypes.bool,
};

export default ExploreSectionPreview;
