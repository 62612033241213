import React, { useState, Fragment } from 'react';
import { faEye, faCog } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router';

import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import PageHeader from 'components/common/layout/PageHeader';
import PreviewExerciseModal from 'components/exercises/exercise-bank/PreviewExerciseModal';
import CancelModal from 'components/exercises/exercise-form/CancelModal';
import ExerciseSettingsModal from 'components/exercises/exercise-form/ExerciseSettingsModal';

const Header = ({ groups, group, setGroup, types, type, setType, onCancel, saveExercise, loading, invisible, exerciseSettings, setExerciseSettings, settingsModal, setSettingsModal, refresh, exercise }) => {
  const history = useHistory();
  const { exerciseId } = useParams();
  const imageConsistencyToggle = useFeature(toggles.imageConsistency);
  const [cancelModal, setCancelModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const onTypeChange = (newType) => {
    setType(newType);
    history.push({
      search: `?type=${newType.id}&group=${group.id}`,
    });
  };

  const onGroupChange = (newGroup) => {
    setGroup(newGroup);
    history.push({
      search: `?type=${type.id}&group=${newGroup.id}`,
    });
  };

  const path = [
    {
      type: 'text',
      text: exerciseId ? lang.exerciseForm.editExerciseOf : lang.exerciseForm.createExerciseOf,
    },
    {
      type: 'dropdown',
      id: 'types',
      current: type,
      values: types,
      onChange: onTypeChange,
      dataTour: 'exercise-editor-header-type-dropdown',
    },
    {
      type: 'text',
      text: lang.in,
    },
    {
      type: 'dropdown',
      id: 'groups',
      current: group,
      values: groups,
      onChange: onGroupChange,
      dataTour: 'exercise-editor-header-group-dropdown',
    },
  ];

  const actions = [
    {
      type: 'icon',
      icon: faEye,
      label: lang.exerciseForm.previewExercise,
      onClick: () => setShowPreviewModal(true),
      hide: !imageConsistencyToggle,
    },
    {
      type: 'icon',
      label: lang.settings,
      onClick: () => setSettingsModal(true),
      icon: faCog,
      hide: !imageConsistencyToggle,
    },
    {
      type: 'button',
      label: lang.cancel,
      onClick: () => setCancelModal(true),
      color: 'black',
    },
    {
      type: 'button',
      label: lang.save,
      onClick: saveExercise,
      loading: loading,
      dataTour: 'exercise-editor-header-save-exercise',
    },
  ];

  if (!group || !type) {
    return null;
  }

  return (
    <Fragment>
      <PreviewExerciseModal
        open={showPreviewModal}
        close={() => setShowPreviewModal(false)}
        exerciseId={exerciseId}
        refresh={refresh}
        exerciseProps={exercise}
      />
      <CancelModal
        open={cancelModal}
        close={() => setCancelModal(false)}
        cancel={onCancel}
      />
      <ExerciseSettingsModal
        open={settingsModal}
        close={() => setSettingsModal(false)}
        exerciseSettings={exerciseSettings}
        setExerciseSettings={setExerciseSettings}
        save={saveExercise}
      />
      <PageHeader
        path={path}
        actions={actions.filter(el => !el.hide)}
        noSeparators
        invisible={invisible}
      />
    </Fragment>
  );
};

Header.propTypes = {
  groups: PropTypes.array,
  group: PropTypes.object,
  setGroup: PropTypes.func,
  types: PropTypes.array,
  type: PropTypes.object,
  setType: PropTypes.func,
  onCancel: PropTypes.func,
  saveExercise: PropTypes.func,
  loading: PropTypes.bool,
  invisible: PropTypes.bool,
  refresh: PropTypes.func,
  exerciseSettings: PropTypes.object,
  setExerciseSettings: PropTypes.func,
  settingsModal: PropTypes.bool,
  setSettingsModal: PropTypes.func,
  exercise: PropTypes.object,
};

export default Header;
