import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
  codesContainer: {
    padding: '16px 16px 0px 16px',
  },
  codes: {
    display: 'flex',
    marginBottom: '16px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    cursor: 'pointer',
  },
}));
