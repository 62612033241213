import React, { forwardRef } from 'react';
import { faGripVertical, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import PropTypes from 'prop-types';

import useFeature from 'hooks/useFeature';
import toggles from 'toggles';
import { renderGap } from 'utils';

import Input from 'components/common/Input';

import useStyles from './styles';

const ExtraGap = forwardRef(({ ...props }, ref) => {
  const classes = useStyles();
  const exportIdentifiersToggle = useFeature(toggles.exportIdentifiers);

  const { item, isDragging, draggable, attributes, listeners, removeGap, restricted, setGaps, type } = props;

  const style = {
    ...props.style,
    boxShadow: isDragging ? '2px 5px 10px #00000066' : undefined,
    transform: isDragging ? 'scale3d(1.01, 1.01, 1)' : undefined,
    touchAction: 'none',
  };

  return (
    <div
      style={style}
      className={cx(classes.extraGap, { isCorrect: item.isCorrect, restricted })}
      {...props}
      {...(draggable ? attributes : {})}
      {...(draggable ? listeners : {})}
      ref={ref}
    >
      {(draggable || isDragging) && (
        <FontAwesomeIcon
          icon={faGripVertical}
        />
      )}
      {(!restricted || item.isCorrect) && (
        <div
          dangerouslySetInnerHTML={{ __html: renderGap(item) }}
        />
      )}
      {!item.isCorrect && restricted && (
        type === 'caption' ? (
          <Input
            type='text'
            value={item.text}
            className={classes.caption}
            onChange={(event) => {
              setGaps(gaps => {
                return gaps.map(gap => {
                  if (gap.id === item.id) {
                    return {
                      ...gap,
                      text: event.target.value,
                    };
                  }
                  return gap;
                });
              });
            }}
          />
        ) : (
          <Input
            type='richtext'
            value={{ ops: JSON.parse(item.text) }}
            onChange={(event) => {
              setGaps(gaps => {
                return gaps.map(gap => {
                  if (gap.id === item.id) {
                    return {
                      ...gap,
                      text: JSON.stringify(event.ops),
                    };
                  }
                  return gap;
                });
              });
            }}
          />
        )
      )}
      {exportIdentifiersToggle && item?.identifier &&
        <div className={classes.identifier}>
          (
          {item?.identifier}
          )
        </div>
      }
      {!item.isCorrect && removeGap && !restricted && (
        <div
          className={classes.remove}
          onClick={() => removeGap(item.id)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
      )}
      {(item.isCorrect || !removeGap) && (
        <div className={classes.emptySpace} />
      )}
    </div>
  );
});

ExtraGap.displayName = 'ExtraGap';

ExtraGap.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  item: PropTypes.object,
  index: PropTypes.number,
  updateItem: PropTypes.func,
  deleteItem: PropTypes.func,
  itemsLength: PropTypes.number,
  isDragging: PropTypes.bool,
  isSorting: PropTypes.bool,
  attributes: PropTypes.object,
  listeners: PropTypes.object,
  draggable: PropTypes.bool,
  offset: PropTypes.number,
  node: PropTypes.node,
  style: PropTypes.object,
  removeGap: PropTypes.func,
  restricted: PropTypes.bool,
  setGaps: PropTypes.func,
};

export default ExtraGap;
