import React, { useCallback, useRef, useState } from 'react';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Toggle } from '@intuitivo/outline';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Xarrow, { Xwrapper, useXarrow } from 'react-xarrows';
import { v4 } from 'uuid';

import { LINE } from 'constants/exerciseOptions';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import FlowStep from '../FlowStep';

import useStyles from './styles';

const AskForSegments = ({ number, image, connectors, setConnectors, connections, setConnections, option, setOption, restricted }) => {
  const classes = useStyles();
  const exportIdentifiersToggle = useFeature(toggles.exportIdentifiers);

  const [activeStart, setActiveStart] = useState(null);
  const [line, setLine] = useState(option === LINE);

  const imageRef = useRef();
  const ref = useRef();

  const _setLine = (checked) => {
    setLine(checked);
    setOption(checked ? LINE : null);

    if (checked) {
      setConnections(connections => {
        const uniqueConnectionsMap = new Map();

        connections.forEach(connection => {
          const connectionKey = `${connection.start}-${connection.end}`;
          const reverseConnectionKey = `${connection.end}-${connection.start}`;

          if (!uniqueConnectionsMap.has(connectionKey) && !uniqueConnectionsMap.has(reverseConnectionKey)) {
            uniqueConnectionsMap.set(connectionKey, connection);
          }
        });

        return Array.from(uniqueConnectionsMap.values());
      });
    }
  };
  const setActive = useCallback((connectorId) => {
    if (activeStart === connectorId) {
      setActiveStart(null);
      return;
    }

    if (!activeStart) {
      setActiveStart(connectorId);
    } else {
      setConnections(connections => {
        const newConnections = [...connections];
        const isLineOption = LINE === option;
        const duplicateCheck = newConnections.some(connection =>
          (connection.start === activeStart && connection.end === connectorId) ||
          (isLineOption && connection.start === connectorId && connection.end === activeStart),
        );

        if (!duplicateCheck) {
          newConnections.push({
            id: v4(),
            start: activeStart,
            end: connectorId,
          });
        }

        return newConnections;
      });
      setActiveStart(null);
    }
  }, [activeStart, option, setConnections]);

  const deleteConnection = useCallback((connectionId) => {
    setConnections(connections => {
      return connections.filter(el => el.id !== connectionId);
    });
  }, [setConnections]);

  const deleteConnector = useCallback((event, connectorId) => {
    setActiveStart(null);
    event.stopPropagation();
    setConnectors(connectors => {
      const copyConnectors = [...connectors];
      const newConnectors = copyConnectors.filter(el => el.id !== connectorId);
      return newConnectors.map((connector, index) => ({ ...connector, order: index }));
    });
    const filteredConnections = connections.filter(connection => connection.start !== connectorId && connection.end !== connectorId);
    setConnections(filteredConnections);
  }, [setConnectors, connections, setConnections]);

  const getContent = useCallback(() => {

    const imageSideSize = 700;

    const connectorsToRender = connectors.map(connector => {

      if (!connector.coords) {
        return null;
      }

      const x = (connector.coords.x / 100) * imageSideSize, y = (connector.coords.y / 100) * imageSideSize;

      const style = {
        position: 'absolute',
        width: '28px',
        height: '28px',
        top: y - 16 + 'px',
        left: x - 16 + 'px',
      };

      return (
        <div key={connector.id} className={classes.outerRing} style={style}>
          {!restricted && (
            <div className={classes.delete} onClick={(event) => deleteConnector(event, connector.id)}>
              <FontAwesomeIcon icon={faCircleXmark} className={classes.deleteIcon} />
            </div>
          )}
          {exportIdentifiersToggle && (
            <div className={classes.identifier}>
              {connector.identifier}
            </div>
          )}
          <div id={connector.id} className={cx(classes.innerRing, { used: activeStart === connector.id })} onClick={() => setActive(connector.id)} />
        </div>
      );
    });

    const connectionsToRender = connections.map(connection => (
      <Xarrow
        key={connection.id}
        start={connection.start}
        end={connection.end}
        curveness={0}
        lineColor={'#419bb4'}
        headColor={'#419bb4'}
        showHead={option !== LINE}
        arrowBodyProps={{ onClick: () => deleteConnection(connection.id) }}
        SVGcanvasStyle={{ cursor: 'pointer' }}
        labels={{ middle: <FontAwesomeIcon icon={faCircleXmark} className={classes.arrowDelete} onClick={() => deleteConnection(connection.id)} ></FontAwesomeIcon> }}
      />
    ));

    return (
      <>
        {connectorsToRender}
        {connectionsToRender}
      </>
    );

  }, [connectors, connections, classes, restricted, exportIdentifiersToggle, activeStart, deleteConnector, setActive, option, deleteConnection]);

  const onClick = (event) => {
    if (restricted) {
      return;
    }

    if (!event.target.classList.toString().includes('imageWrapper')) {
      return;
    }

    const rect = imageRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const imageSideSize = 700;

    const connector = {
      id: v4(),
      value: null,
      order: connectors.length + 1,
      coords: {
        x: x * 100 / imageSideSize,
        y: y * 100 / imageSideSize,
      },
      identifier: `connector_${connectors.length + 1}`,
    };

    setConnectors(connectors => {
      const newConnectors = [...connectors];
      newConnectors.push(connector);
      return newConnectors.map((connector, index) => ({ ...connector, order: index }));
    });
  };

  return (
    <FlowStep
      stepNumber={number}
      header={
        <div className={classes.headerWrapper}>
          <div>
            {lang.exerciseForm.segmentation.createConnectorsAndConnections}
          </div>
          {!restricted && (
            <div className={classes.toggleWrapper}>
              {lang.exerciseForm.segmentation.showOnlyLine}
              <Toggle
                checked={line}
                onChange={(checked) => _setLine(checked)}
              />
            </div>
          )}
        </div>
      }
      subHeader={lang.exerciseForm.segmentation.createConnectorsAndConnectionsDescription}
    >
      <div className={classes.wrapper} ref={ref}>
        <div onLoad={useXarrow()} className={classes.imageWrapper} onClick={onClick} style={{ backgroundImage: `url(${image})` }} ref={imageRef}>
          <Xwrapper>
            {getContent()}
          </Xwrapper>
        </div>
      </div>
    </FlowStep>
  );
};

AskForSegments.propTypes = {
  number: PropTypes.number,
  image: PropTypes.array,
  connectors: PropTypes.array,
  setConnectors: PropTypes.func,
  connections: PropTypes.array,
  setConnections: PropTypes.func,
  option: PropTypes.string,
  setOption: PropTypes.func,
  restricted: PropTypes.bool,
};

export default AskForSegments;
