import React, { useCallback, useEffect, useState } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { selectAttemptItems, setAttemptItems, setNavigationCurrentItem } from 'actions/studentAttemptActions';
import { selectUserLoggedIn } from 'actions/userActions';
import api from 'api';
import { INFORMATION, PAUSE } from 'constants/exerciseTypes';
import { LINEAR, NON_LINEAR } from 'constants/navigationTypes';
import { FULL, INCREMENTAL } from 'constants/presentationTypes';
import { ERROR } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import lang from 'lang';

import AnswerableSection from '../AnswerableSection';
import AttemptActions from '../AttemptActions';
import PauseItem from '../PauseItem';
import AnswerableExercise from 'components/attempt/AnswerableExercise';
import Loading from 'components/common/Loading';

import useStyles from './styles';

const AttemptBaseItems = ({ attemptId, finishTest, finishLoading, navigation, attemptType, setPendingAnswer, flushPendingAnswers, pendingAnswers, clearPendingAnswers }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const classes = useStyles();
  const loggedIn = useSelector(selectUserLoggedIn);
  const [getAttemptBaseItemsRequest] = useApi(api.getAttemptBaseItems, !loggedIn);
  const [attemptNextBaseItemRequest] = useApi(api.attemptNextBaseItem, !loggedIn);
  const [attemptPreviousBaseItemRequest] = useApi(api.attemptPreviousBaseItem, !loggedIn);
  const items = useSelector(selectAttemptItems);
  const currentItem = useSelector((state) => state.studentAttempt.currentItem);

  const [savingAnswer, setSavingAnswer] = useState(false);
  const [loading, setLoading] = useState(true);
  const [writing, setWriting] = useState(false);

  const fetchStudentAttempt = useCallback(() => {
    getAttemptBaseItemsRequest([attemptId], null, ({ data }) => {
      setTimeout(() => {
        if (data.status === 0) {
          dispatch(setAttemptItems(data.items));
          setLoading(false);
          return;
        }

        if (data.status !== ERROR) {
          toast.error(lang.oops);
          setLoading(false);
        }
      }, 200);
    });
  }, [getAttemptBaseItemsRequest, attemptId, dispatch, toast]);

  useEffect(() => {
    setLoading(true);
    if (attemptId) {
      fetchStudentAttempt();
    } else {
      dispatch(setAttemptItems([]));
    }
  }, [fetchStudentAttempt, attemptId, dispatch, toast, history, currentItem]);

  const nextItem = () => {
    clearPendingAnswers();

    setLoading(true);
    attemptNextBaseItemRequest([attemptId], null, ({ data }) => {
      if (data.status === 0) {
        dispatch(setNavigationCurrentItem(currentItem + 1));
        fetchStudentAttempt();
        return;
      }

      setLoading(false);
      if (data.status === 2 || data.status === 3) {
        toast.warning(lang.test.finishTestEnded);
        history.push('/');
        return;
      }

      if (data.status !== ERROR) {
        toast.error(lang.oops);
      }
    });
  };

  const previousItem = () => {
    clearPendingAnswers();

    setLoading(true);
    attemptPreviousBaseItemRequest([attemptId], null, ({ data }) => {
      if (data.status === 0) {
        dispatch(setNavigationCurrentItem(currentItem - 1));
        fetchStudentAttempt();
        return;
      }

      setLoading(false);
      if (data.status === 2 || data.status === 3) {
        toast.warning(lang.test.finishTestEnded);
        history.push('/');
        return;
      }

      if (data.status !== ERROR) {
        toast.error(lang.oops);
      }
    });
  };

  const getItems = () => {
    if (!items) {
      return;
    }

    let itemCount = 0;
    return items.map((item) => {
      if (item.itemType === 'section') {
        return (
          <AnswerableSection
            key={item.id}
            sectionId={item.id}
            savingAnswer={savingAnswer}
            setSavingAnswer={setSavingAnswer}
            setWriting={setWriting}
            writing={writing}
            setPendingAnswer={setPendingAnswer}
            flushPendingAnswers={flushPendingAnswers}
            pendingAnswers={pendingAnswers}
            clearPendingAnswers={clearPendingAnswers}
          />
        );
      }

      if (item.type === PAUSE) {
        return (
          <PauseItem
            key={item.answerId}
            navigation={navigation}
            nextItem={nextItem}
            previousItem={previousItem}
            statement={item.statement}
            finishTest={finishTest}
            flushPendingAnswers={flushPendingAnswers}
            pendingAnswers={pendingAnswers}
          />
        );
      }

      if (item.type !== INFORMATION) {
        itemCount++;
      }

      return (
        <AnswerableExercise
          key={item.answerId}
          num={item.exerciseCounter || itemCount}
          answerId={item.answerId}
          setSavingAnswer={setSavingAnswer}
          setWriting={setWriting}
          setPendingAnswer={setPendingAnswer}
        />
      );
    });
  };

  if (loading) {
    return (
      <Loading active={loading} />
    );
  }

  return (
    <div className={classes.attemptContent}>
      {getItems()}
      {items && items[0]?.type !== PAUSE && (
        <AttemptActions
          nextItem={nextItem}
          previousItem={previousItem}
          finishTest={finishTest}
          buttonLabel={lang.test.finishTest}
          savingAnswer={savingAnswer}
          isLast={attemptType === FULL || items[0].isLast}
          canMovePrevious={items[0].itemType !== 'section' || items[0].presentation !== INCREMENTAL || items[0].navigation === LINEAR || items[0].isFirstSectionItem || (navigation === NON_LINEAR && items[0].sectionEnded)}
          canMoveNext={attemptType === FULL || items[0].itemType !== 'section' || items[0].presentation !== INCREMENTAL || items[0].isLastSectionItem || items[0].sectionEnded}
          finishLoading={finishLoading}
          navigation={navigation}
          isFirst={items[0].order === 0}
          finishable
          writing={writing}
          flushPendingAnswers={flushPendingAnswers}
          pendingAnswers={pendingAnswers}
        />
      )}
    </div>
  );
};

AttemptBaseItems.propTypes = {
  attemptId: PropTypes.string,
  finishTest: PropTypes.func.isRequired,
  finishLoading: PropTypes.bool,
  navigation: PropTypes.string,
  attemptType: PropTypes.string,
  setPendingAnswer: PropTypes.func,
  flushPendingAnswers: PropTypes.func,
  pendingAnswers: PropTypes.instanceOf(Map),
  clearPendingAnswers: PropTypes.func,
};

export default AttemptBaseItems;
