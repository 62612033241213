import React, { useCallback, useMemo } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Toggle } from '@intuitivo/outline';
import cx from 'classnames';
import omit from 'lodash.omit';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';

import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import FlowStep from '../FlowStep';
import Button from 'components/common/Button';
import QuillRenderer from 'components/common/QuillRenderer';

import useStyles from './styles';

const SPECIAL_CHAR_REGEX = /(\s|[§/\\±"$&+,:;=?@#|<>.^*()%!])/g;

const AskForGaps = ({ number, statement, setStatement, gaps, setGaps, orderGaps, setOrderGaps }) => {
  const classes = useStyles();
  const exportIdentifiersToggle = useFeature(toggles.exportIdentifiers);

  const parseStatement = (oldOps) => {
    const words = [];
    if (oldOps) {
      oldOps?.forEach((op, opIndex) => {
        if (typeof op.insert === 'string') {
          let charCount = 0;
          op.insert
            .split(SPECIAL_CHAR_REGEX)
            .forEach((word) => {
              if (word.replace(' ', '').length !== 0) {
                words.push({
                  insert: word,
                  attributes: {
                    ...op.attributes,
                    position: v4(),
                  },
                  opIndex,
                  startIndex: charCount,
                });
              }
              charCount += word.length;
            });
        } else {
          words.push({
            ...op,
            attributes: {
              ...op.attributes,
              position: op.attributes ? op.attributes.position?.toString() ?? v4() : v4(),
            },
            opIndex,
          });
        }
      });
    }
    return words;
  };

  const words = useMemo(() => {
    if (statement) {
      return parseStatement(statement.ops);
    }

    return [];
  }, [statement]);

  const addGapToStatement = useCallback((word) => {
    const op = statement.ops[word.opIndex];

    if (typeof op.insert === 'string') {
      const newGap = word.insert;
      const left = op.insert.slice(0, word.startIndex);
      const right = op.insert.slice(word.startIndex + newGap.length);

      const newStatement = {
        ops: [
          ...statement.ops.slice(0, word.opIndex),
          { insert: left, attributes: op.attributes },
          { insert: { gap: newGap }, attributes: word.attributes },
          { insert: right, attributes: op.attributes },
          ...statement.ops.slice(word.opIndex + 1),
        ],
      };

      setStatement(newStatement);
    } else if (word.insert.formula) {
      const newStatement = {
        ops: [
          ...statement.ops.slice(0, word.opIndex),
          { insert: { gap: word.insert }, attributes: word.attributes },
          ...statement.ops.slice(word.opIndex + 1),
        ],
      };

      setStatement(newStatement);
    }
  }, [setStatement, statement]);

  const removeGapFromStatement = useCallback((word) => {
    const op = statement.ops[word.opIndex];
    const isOpString = typeof op?.insert === 'string';
    const previousOp = statement.ops[word.opIndex - 1];
    const isPreviousOpString = typeof previousOp?.insert === 'string';
    const nextOp = statement.ops[word.opIndex + 1];
    const isNextOpString = typeof nextOp?.insert === 'string';

    let newOp = { insert: op.insert.gap, attributes: omit(op.attributes, ['position']) };

    const canJoinPrevious = isOpString && isPreviousOpString;
    if (canJoinPrevious) {
      newOp = {
        ...newOp,
        insert: previousOp.insert + newOp.insert,
      };
    }

    const canJoinNext = isOpString && isNextOpString;
    if (canJoinNext) {
      newOp = {
        ...newOp,
        insert: newOp.insert + nextOp.insert,
      };
    }

    const newStatement = {
      ops: [
        ...statement.ops.slice(0, canJoinPrevious ? word.opIndex - 1 : word.opIndex),
        newOp,
        ...statement.ops.slice(canJoinNext ? word.opIndex + 2 : word.opIndex + 1),
      ],
    };

    setStatement(newStatement);
  }, [setStatement, statement]);

  const joinGapsInStatement = useCallback((leftWord, rightWord) => {
    const opsToJoin = statement.ops.slice(leftWord.opIndex, rightWord.opIndex + 1);

    const newOpValue = opsToJoin.reduce((acc, curr) => {
      if (typeof curr.insert === 'string') {
        acc += curr.insert;
      } else {
        acc += curr.insert.gap;
      }
      return acc;
    }, '');

    const newStatement = {
      ops: [
        ...statement.ops.slice(0, leftWord.opIndex),
        { insert: { gap: newOpValue }, attributes: { ...leftWord.attributes, position: leftWord.attributes.position } },
        ...statement.ops.slice(rightWord.opIndex + 1),
      ],
    };

    setStatement(newStatement);

    return newOpValue;
  }, [setStatement, statement]);

  const onClick = (word) => {
    if (typeof word.insert === 'string' && word.insert !== ' ') {
      setGaps([
        ...gaps,
        {
          id: v4(),
          text: JSON.stringify(word),
          position: word.attributes.position,
          isCorrect: true,
          identifier: `gap_${gaps.length + 1}`,
        },
      ]);
      addGapToStatement(word);
    } else if (word.insert.formula) {
      setGaps([
        ...gaps,
        {
          id: v4(),
          text: JSON.stringify(word.insert),
          position: word.attributes.position,
          isCorrect: true,
          identifier: `gap_${gaps.length + 1}`,
        },
      ]);
      addGapToStatement(word);
    } else if (word.insert.gap) {
      removeGapFromStatement(word);
      const newGaps = gaps
        .filter(gap => gap.position !== word.attributes.position);

      setGaps(newGaps);
    }
  };

  const joinGaps = (leftWord, rightWord) => {
    const newOpValue = joinGapsInStatement(leftWord, rightWord);

    const newGaps = [
      ...gaps.filter(gap => gap.position !== leftWord.attributes.position && gap.position !== rightWord.attributes.position),
      {
        id: v4(),
        text: JSON.stringify({ insert: newOpValue }),
        position: leftWord.attributes.position,
        isCorrect: true,
        identifier: gaps.find(el => el.position === leftWord.attributes.position).identifier,
      },
    ];

    setGaps(newGaps);
  };

  return (
    <FlowStep
      stepNumber={number}
      subHeader={lang.exerciseForm.gap.choosingGapsSub}
      header={
        <div className={classes.headerWrapper}>
          <div>
            {lang.exerciseForm.gap.choosingGaps}
          </div>
          <div className={classes.toggleWrapper}>
            {lang.exerciseForm.gap.orderGaps}
            <Toggle
              checked={orderGaps}
              onChange={(checked) => setOrderGaps(checked)}
            />
          </div>
        </div>
      }
    >
      <div className={classes.gapContainer}>
        {words.length === 0 && (
          <div className={classes.noGaps}>
            {lang.exerciseForm.gap.noGaps}
          </div>
        )}
        {words.map((word, index) => {
          const gap = gaps.find(gap => gap.position === word.attributes.position);

          const isNotMedia = ['image', 'audio', 'video', 'custom-audio', 'custom-video'].every(key => !word.insert[key]);
          const wordOp = word.insert.gap ? { ...word, insert: word.insert.gap } : word;

          const nextWord = words[index + 1];

          const wordGapIsString = typeof word?.insert.gap === 'string';
          const nextWordGapIsString = typeof nextWord?.insert.gap === 'string';

          const wordIsGap = gaps.some(gap => gap.position === word?.attributes.position);
          const nextWordIsGap = gaps.some(gap => gap.position === nextWord?.attributes.position);

          const canAddToNextWord = wordGapIsString && nextWordGapIsString && wordIsGap && nextWordIsGap;

          if (word.insert.length !== 0 && word.insert !== '\n' && isNotMedia) {
            return (
              <>
                <Button
                  key={word.attributes.position}
                  onClick={() => onClick(word)}
                  className={cx(classes.gapButton, { selected: wordIsGap })}
                  sibling
                >
                  <QuillRenderer value={{ ops: [wordOp] }} />
                  {exportIdentifiersToggle && gap?.identifier &&
                    <div className={classes.identifier}>
                      (
                      {gap?.identifier}
                      )
                    </div>
                  }
                </Button>
                {canAddToNextWord && (
                  <Button className={classes.joinButton} onClick={() => joinGaps(word, nextWord)}>
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                )}
              </>
            );
          }

          return false;
        })}
      </div>
    </FlowStep>
  );
};

AskForGaps.propTypes = {
  number: PropTypes.number,
  statement: PropTypes.string,
  setStatement: PropTypes.func,
  gaps: PropTypes.array,
  setGaps: PropTypes.func,
  orderGaps: PropTypes.bool,
  setOrderGaps: PropTypes.func,
};

export default AskForGaps;
