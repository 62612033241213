import React, { useEffect, useState } from 'react';
import { Wrapper } from '@intuitivo/outline';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { selectTest } from 'actions/testActions';
import { selectUserIsAdmin } from 'actions/userActions';
import useQuery from 'hooks/common/useQuery';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import ExercisesTab from '../ExercisesTab';
import CorrectionTab from '../grades-tab/CorrectionTab';
import { AttemptProvider } from '../grades-tab/CorrectionTab/context';
import PhysicalTab from '../physical-tab/PhysicalTab';
import RubricTab from '../RubricTab';
import StatisticsTab from '../statistics-tab/StatisticsTab';

import useStyles from './styles';

const TestTabs = ({ fetchTest }) => {
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery();
  const test = useSelector(selectTest);
  const isAdmin = useSelector(selectUserIsAdmin);
  const statsToggle = useFeature(toggles.statistics);

  const TAB_EXERCISES = 'exercises';
  const TAB_RUBRIC = 'rubric';
  const TAB_GRADES = 'grades';
  const TAB_PHYSICAL = 'physical';
  const TAB_STATISTICS = 'statistics';

  const [tab, setTab] = useState(null);

  const tabs = [
    {
      label: lang.appKeywords.exercises,
      _tab: TAB_EXERCISES,
      show: true,
      dataTour: 'assessment-tab-exercises',
    },
    {
      label: lang.appKeywords.rubric,
      _tab: TAB_RUBRIC,
      show: test.classificationType === 'rubric',
      dataTour: 'assessment-tab-rubric',
    },
    {
      label: lang.appKeywords.correction,
      _tab: TAB_GRADES,
      show: true,
      dataTour: 'assessment-tab-grades',
    },
    {
      label: lang.appKeywords.statistics,
      _tab: TAB_STATISTICS,
      show: statsToggle,
      dataTour: 'assessment-tab-statistics',
    },
    {
      label: lang.test.physicalVersion.physicalVersion,
      _tab: TAB_PHYSICAL,
      show: true,
      dataTour: 'assessment-tab-physical-version',
    },
  ];

  const changeTab = (newTab) => {
    setTab(newTab);
    history.replace({
      search: `?tab=${newTab}`,
    });
  };

  const getTabs = () => (
    <div className={classes.tabsContainer}>
      <div className={classes.tabsWrap}>
        {tabs
          .filter(({ show }) => show)
          .map(({ _tab, label, dataTour }, index) => (
            <div
              key={index}
              data-tour={dataTour}
              className={cx(classes.tabHeader, {
                active: tab === _tab,
              })}
              onClick={() => changeTab(_tab)}
            >
              {label}
            </div>
          ))}
      </div>
    </div>
  );

  useEffect(() => {
    const currentTab = query().get('tab');

    if (isAdmin) {
      setTab(TAB_GRADES);
    } else if (!currentTab || currentTab === TAB_EXERCISES) {
      setTab(TAB_EXERCISES);
    } else if (currentTab === TAB_GRADES) {
      setTab(TAB_GRADES);
    } else if (currentTab === TAB_PHYSICAL) {
      setTab(TAB_PHYSICAL);
    } else if (currentTab === TAB_STATISTICS) {
      setTab(TAB_STATISTICS);
    } else if (currentTab === TAB_RUBRIC) {
      setTab(TAB_RUBRIC);
    }
  }, [history.location.search, query, isAdmin]);

  return (
    <>
      {!isAdmin && (
        getTabs()
      )}
      {tab === TAB_EXERCISES && (
        <div className={classes.tabContentWrap}>
          <ExercisesTab
            fetchTest={fetchTest}
          />
        </div>
      )}
      <Wrapper>
        {tab === TAB_GRADES && (
          <AttemptProvider>
            <CorrectionTab changeTab={changeTab} />
          </AttemptProvider>
        )}
        {tab === TAB_PHYSICAL && (
          <PhysicalTab />
        )}
        {tab === TAB_STATISTICS && (
          <StatisticsTab />
        )}
        {tab === TAB_RUBRIC && (
          <RubricTab
            refresh={fetchTest}
          />
        )}
      </Wrapper>
    </>
  );
};

TestTabs.propTypes = {
  fetchTest: PropTypes.func.isRequired,
};

export default TestTabs;
