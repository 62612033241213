import { createUseStyles } from 'react-jss';

export default createUseStyles({
  modal: {
    width: '100% !important',
    margin: '20px',
    maxWidth: '1200px !important',
  },
  modalContent: {
    display: 'flex',
    justifyContent: 'center',
  },
});
