import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px',
  },
  columnWrapper: {
    display: 'grid',
    gridTemplateColumns: '40% 40%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  connector: {
    width: '100%',
    height: '100%',
  },
  arrowDelete: {
    color: theme.removeColor,
    backgroundColor: 'white',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  choiceRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  checkBtn: {
    fontSize: '30px',
    marginRight: '10px',
    cursor: 'pointer',
    color: theme.unselectedColor2,
    '&:hover': {
      color: theme.unselectedHover2,
    },
    '&.active': {
      color: theme.selectedColor2,
      '&:hover': {
        color: theme.selectedHover2,
      },
    },
  },
  newChoiceText: {
    marginLeft: '10px',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  toggleWrapper: {
    display: 'flex',
    gap: '0.35em',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: '300 !important',
  },
  orderItemWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  identifier: {
    color: theme.textColor5,
    marginLeft: '5px',
    fontSize: '14px',
    whiteSpace: 'nowrap',
  },
}));
