import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  section: {
    marginBottom: '20px',
    width: 'unset !important',
  },
  sectionDescription: {
    '& > p': {
      margin: '0',
    },
  },
  reviewableExercise: {
    marginBottom: '20px',
  },
  sectionContent: {
    padding: '20px 10px',
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    section: {
      width: 'calc(100% + 20px) !important',
    },
  },
}));
