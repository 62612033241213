import React, { Fragment, useCallback, useState } from 'react';
import { faCopy, faPenRuler, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import { useTheme } from 'react-jss';

import { INFORMATION, PAUSE } from 'constants/exerciseTypes';
import lang from 'lang';
import { classificationTestTypeString, exerciseTypeString, testTypeString } from 'utils';

import ExploreSectionPreview from '../ExploreSectionPreview';
import ExploreTestPreviewModal from '../ExploreTestPreviewModal';
import Button from 'components/common/Button';
import Card from 'components/common/Card';
import FilterPoolItem from 'components/common/filter-selector/FilterPoolItem';
import PreviewableExercise from 'components/exercises/PreviewableExercise';

import { containerMotion } from './motions';
import useStyles from './styles';

const ExploreTest = ({ test, dataTour }) => {
  const classes = useStyles();
  const theme = useTheme();

  const { testName, testType, classificationType, numExercises, exercisesTypes, items, usages, subjects, years } = test;

  let exerciseOrder = 0;

  const [open, setOpen] = useState(false);
  const [copy, setCopy] = useState({
    state: false,
    from: null,
  });

  const openCopy = useCallback((from) => {
    setCopy({
      state: true,
      from: from,
    });
    setOpen(true);
  }, []);

  const close = useCallback((exit) => {

    setCopy({
      state: false,
      from: null,
    });

    if (copy.from !== 'modal' || exit) {
      setOpen(false);
    }

  }, [copy]);

  const getPills = (type, items) => {
    let color;
    let secondColor;

    if (!items) {
      return null;
    }
    if (type === 'subject') {
      color = '#CB6EB7';
      secondColor = '#FEDFF7';
    } else {
      color = '#F79649';
      secondColor = '#FCE9DA';
    }

    const content = (
      <div className={classes.innerPill}>
        <div className={cx(classes.pillLabel, { hasMore: items.length > 1 })}>
          {items[0]}
        </div>
        {items.length > 1 ?
          <div className={classes.pillPlus} style={{ backgroundColor: secondColor, color: color }}>
            {` +${items.length - 1}`}
          </div>
          : null}
      </div>
    );

    return (
      <FilterPoolItem poolItem={{ name: content, color }} selectable />
    );
  };

  const colors = {
    textColor: theme.white,
    headerColor: theme.testColor,
    bodyColor: theme.testColorTransparency,
  };

  return (
    <Fragment>
      <motion.div
        layoutId={test.id}
        initial="rest"
        whileHover="hover"
        variants={containerMotion}
        className={classes.testContainer}
        onClick={() => setOpen(true)}
      >
        <Card dataTour={dataTour} className={classes.testCard}>
          <motion.div
            className={classes.testContent}
          >

            <div className={classes.testHeader}>

              <motion.div className={classes.testType}>
                <span
                  className={classes.testTypeText}
                  title={classificationTestTypeString(classificationType)}
                >
                  {testTypeString(testType)}
                  <span> &#8226; </span>
                  {classificationTestTypeString(classificationType)}
                </span>
              </motion.div>

              <motion.div className={classes.testUsages}>
                <FontAwesomeIcon
                  icon={faUserGroup}
                  className={classes.testUsagesIcon}
                />
                {usages}
              </motion.div>
              <motion.div className={classes.testTitle}>
                {testName}
              </motion.div>
            </div>
            <div className={classes.exercisesInfo}>
              <div className={classes.exercisesNumber}>
                <FontAwesomeIcon
                  icon={faPenRuler}
                  className={classes.exercisesNumberIcon}
                />
                {numExercises}
                {' '}
                {numExercises > 1 ? lang.tests.teacher.exercises : lang.tests.teacher.exercise}
                {':'}
              </div>
              <Render when={exercisesTypes}>
                <div className={classes.exerciseTypes}>
                  {exercisesTypes?.map((type) => (
                    <span key={type}>
                      {exerciseTypeString(type)}
                      {exercisesTypes.length - 1 !== exercisesTypes.indexOf(type) ? '; ' : null}
                    </span>
                  ))}
                </div>
              </Render>
            </div>
            <div className={classes.filters}>
              {getPills('subject', subjects)}
              {getPills('year', years)}
            </div>
            <div className={classes.testItems}>
              {items.slice(0, 2).map((item) => {
                if (item.exerciseId) {
                  if (![INFORMATION, PAUSE].includes(item.type)) {
                    exerciseOrder = exerciseOrder += 1;
                  }

                  return (
                    <PreviewableExercise
                      {...item}
                      key={item.id}
                      colors={colors}
                      header={(![INFORMATION, PAUSE].includes(item.type)) ? lang.test.question + ' ' + (exerciseOrder) : lang.test.informationBlock}
                      isExploreTest
                      isFullWidth
                    />
                  );
                } else if (item.testId) {
                  return (
                    <ExploreSectionPreview
                      {...item}
                      key={item.id}
                      colors={colors}
                      isExploreTest
                      isFullWidth
                    />
                  );
                }
                return null;
              })}
            </div>
          </motion.div>
          <Button className={classes.useButton} onClick={() => openCopy('list')}>
            <FontAwesomeIcon icon={faCopy} className={classes.icon} />
          </Button>
        </Card>
      </motion.div>
      <AnimatePresence>
        <ExploreTestPreviewModal
          dataTour={`${dataTour}-preview-modal`}
          open={open}
          close={close}
          test={test}
          copy={copy}
          openCopy={() => openCopy('modal')}
        />
      </AnimatePresence>
    </Fragment >
  );
};

ExploreTest.propTypes = {
  test: PropTypes.object,
  dataTour: PropTypes.string,
};

export default ExploreTest;
