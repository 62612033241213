import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  handle: {
    fontSize: '26px',
    color: `${theme.buttonBackground2}B3`,
    '&:hover': {
      color: `${theme.buttonBackground2}`,
      cursor: 'pointer',
    },
  },
  itemWrapper: {
    backgroundColor: theme.buttonBackground3,
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.buttonBorder3}`,
    borderRadius: '8px',
    padding: '0.5em',
    gap: '0.5em',
    marginBottom: '0.5em',
    cursor: 'pointer',
    minHeight: '25px',
  },
  deleteBtn: {
    fontSize: '20px',
    cursor: 'pointer',
    color: theme.removeColor,
    '&.disabled': {
      color: theme.unselectedColor2,
      cursor: 'not-allowed',
    },
  },
  optionInput: {
    '& .ql-toolbar': {
      padding: '0',
      border: 'none',
    },
  },
  statement: {
    '&.collapse': {
      width: '100%',
      wordBreak: 'break-all',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      lineClamp: '3',
      boxOrient: 'vertical',
      '& p': {
        margin: '0 !important',
      },
      '& img.ql-image': {
        maxHeight: '50px !important',
        marginTop: '2px !important',
      },
    },
  },
  identifier: {
    color: theme.textColor5,
    marginLeft: '5px',
    fontSize: '14px',
    whiteSpace: 'nowrap',
  },
}));
