import React, { useCallback, useRef, useState } from 'react';
import { faSquareRootAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Quill from 'quill';
import { useDispatch, useSelector } from 'react-redux';

import { openFormulaModal, selectFormulaModalOptions } from 'actions/formulaModalActions';
import lang from 'lang';
import { quillIsEmpty } from 'utils';

import expressions from '../FormulaModal/expressions';
import ReactQuill from '../ReactQuill';
import QuillRenderer from 'components/common/QuillRenderer';
import Tooltip from 'components/common/Tooltip';

import useStyles from './styles';

const MiniRichText = ({ value, placeholder, mathSymbols, enableMathSymbols, onChange, onBlur, className, disabled, autoComplete }) => {
  const { open } = useSelector(selectFormulaModalOptions);
  const classes = useStyles();
  const dispatch = useDispatch();

  const ref = useRef();
  const [isWritable, setIsWritable] = useState(false);

  const addFormula = useCallback((value) => {
    if (ref.current) {
      const quill = ref.current.getEditor();
      const range = quill.getSelection(true);

      quill.insertEmbed(range.index, 'formula', { value, mathSymbols: JSON.stringify(mathSymbols ?? expressions) }, Quill.sources.USER);
      quill.insertText(range.index + 1, ' ', Quill.sources.USER);
      quill.setSelection(range.index + 2);
    }
  }, [ref, mathSymbols]);

  const formulaHandler = useCallback(() => {
    setIsWritable(true);

    dispatch(openFormulaModal({
      insert: addFormula,
      mathSymbols: mathSymbols,
      enableMathSymbols: enableMathSymbols,
    }));
  }, [addFormula, dispatch, enableMathSymbols, mathSymbols]);

  const onMouseEnter = useCallback(() => {
    setIsWritable(true);
  }, [setIsWritable]);

  const _onChange = useCallback((source, editor) => {
    if (source !== Quill.sources.USER) {
      return;
    }

    onChange(editor.getContents());
  }, [onChange]);

  const _onBlur = useCallback((event) => {
    if (open || event.currentTarget.contains(event.relatedTarget)) {
      return;
    }
    setIsWritable(false);
  }, [setIsWritable, open]);

  return (
    <div
      className={cx(classes.quillContainer, className)}
      onMouseEnter={onMouseEnter}
      onBlur={_onBlur}
    >
      {isWritable && (
        <>
          <ReactQuill
            ref={ref}
            value={value}
            defaultValue={value}
            theme={'bubble'}
            readOnly={disabled}
            onChange={disabled ? null : (_range, _delta, source, editor) => _onChange(source, editor)}
            onBlur={disabled ? null : (_range, _source, editor) => onBlur(editor.getContents())}
            placeholder={placeholder}
            autoComplete={autoComplete}
            modules={{
              dropIdboard: {
                bindings: {
                  'list autofill': {
                    prefix: /(\*|\[ ?\]|\[x\])$/,
                  },
                },
              },
            }}
            style={{
              width: '100%',
              backgroundColor: 'white',
              color: 'black',
            }}
            formats={['formula']}
          />
        </>
      )}
      {!isWritable && (
        <div
          className={cx(classes.contentContainer, { isEmpty: quillIsEmpty(value) })}
          onClick={() => setIsWritable(true)}
        >
          <QuillRenderer
            value={value}
            isWritable={isWritable}
            className={classes.previewWrite}
          />
        </div>
      )}
      {enableMathSymbols && (
        <div className={classes.formulaBtnContainer} tabIndex={'0'}>
          <Tooltip
            tip={lang.exerciseForm.formulaHeader}
            place="right"
          >
            <FontAwesomeIcon
              icon={faSquareRootAlt}
              className={cx(classes.formulaBtn, { disabled })}
              onClick={formulaHandler}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

MiniRichText.propTypes = {
  value: PropTypes.object,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  enableMathSymbols: PropTypes.bool,
  mathSymbols: PropTypes.object,
  autoComplete: PropTypes.string,
};

export default MiniRichText;
