import React, { useState } from 'react';
import { Icon } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import lang from 'lang';

import FlowStep from '../FlowStep';
import Input from 'components/common/Input';
import PreviewExerciseModal from 'components/exercises/exercise-bank/PreviewExerciseModal';

import useStyles from './styles';

const AskForExerciseCodes = ({ number, exerciseCodes, exercise }) => {
  const classes = useStyles();

  const [showExerciseCodeModal, setShowExerciseCodeModal] = useState(false);
  const [answer, setAnswer] = useState(null);

  const _openExerciseCodeModal = (answer) => {
    setAnswer(answer);
    setShowExerciseCodeModal(true);
  };

  return (
    <FlowStep
      stepNumber={number}
      header={lang.exerciseForm.exerciseCodes}
    >
      <div className={classes.codesContainer}>
        <PreviewExerciseModal
          open={showExerciseCodeModal}
          close={() => setShowExerciseCodeModal(false)}
          exerciseProps={{ ...exercise, answer, isCodePreview: true }}
        />
        {
          exerciseCodes?.map((code, index) => {
            return (
              <div key={index} className={classes.codes}>
                <Input
                  type='text'
                  value={code.name}
                  className={classes.input}
                />
                <Icon
                  icon='eye'
                  className={classes.icon}
                  onClick={() => _openExerciseCodeModal(code.answer)}
                />
              </div>
            );
          })
        }
      </div>
    </FlowStep>
  );
};

AskForExerciseCodes.propTypes = {
  number: PropTypes.number.isRequired,
  exerciseCodes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  })).isRequired,
  exercise: PropTypes.object.isRequired,
};

export default AskForExerciseCodes;
