import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  section: {
    marginBottom: '20px',
    width: 'unset !important',
  },
  sectionDescription: {
    '& > p': {
      margin: '0',
    },
    maxHeight: '100px',
    overflow: 'hidden',
    '&.showMore': {
      maxHeight: 'unset',
      overflow: 'visible',
    },
  },
  showMore: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  sectionContent: {
    padding: '20px 10px',
  },
  editableExercise: {
    marginBottom: '20px',
  },
  deleteSection: {
    color: theme.deleteTestExercise,
  },
  sectionPresentationWrapper: {
    display: 'flex',
    fontSize: '14px',
    alignItems: 'center',
    fontWeight: 600,
    color: theme.textColor9,
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    section: {
      width: 'calc(100% + 20px) !important',
    },
  },
}));
