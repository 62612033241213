import React, { useState, useEffect, useCallback } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router';
import { v4 } from 'uuid';

import api from 'api';
import useApi from 'hooks/common/useApi';
import lang from 'lang';
import routes from 'routes';
import { quillIsEmpty } from 'utils';

import Loading from 'components/common/Loading';
import Modal from 'components/common/Modal';
import PreviewableExercise from 'components/exercises/PreviewableExercise';

import useStyles from './styles';

const PreviewExerciseModal = ({ open, close, templateExerciseId, refresh, exerciseProps }) => {
  const toast = useToast();
  const history = useHistory();
  const classes = useStyles();
  const { spaceId } = useParams();
  const [getTemplateExerciseRequest] = useApi(api.getTemplateExercise);

  const [exercise, setExercise] = useState(null);

  const _close = useCallback(() => {
    close();
    setTimeout(() => {
      setExercise(null);
    }, 350);
  }, [close]);

  useEffect(() => {
    if (open === true && templateExerciseId) {
      if (exercise === null) {
        getTemplateExerciseRequest([templateExerciseId], null, ({ data }) => {
          if (data.status === 0) {
            setExercise(data.exercise);
          } else if (data.status === 2 || data.status === 3) {
            toast.error(lang.exercises.previewErrorDoesNotExist);
            _close();
            setTimeout(() => {
              refresh();
            }, 350);
          } else {
            toast.error(lang.common.internetWentWrong);
            _close();
          }
        });
      }
    }
  }, [getTemplateExerciseRequest, templateExerciseId, exercise, open, _close, refresh, toast]);

  const exerciseData = templateExerciseId && exercise
    ? exercise
    : exerciseProps;

  const getActions = () => {
    if (templateExerciseId) {
      return [
        {
          name: lang.edit,
          onClick: () => history.push(routes.editExercise.ref(spaceId, templateExerciseId)),
        },
        {
          name: lang.close,
          color: 'black',
          onClick: () => _close(),
        },
      ];
    } else {
      return [
        {
          name: lang.close,
          color: 'black',
          onClick: () => _close(),
        },
      ];
    }
  };

  const getContent = () => {
    if (exercise === null && templateExerciseId) {
      return (
        <Loading active />
      );
    }

    return (
      <div className={classes.modalContent}>
        <PreviewableExercise
          id={v4()}
          statement={
            !quillIsEmpty(exerciseData.statement) ? exerciseData.statement :
              {
                ops: [{ insert: lang.exerciseForm.statementPlaceholder }],
              }
          }
          choices={
            exerciseData.choices?.map((choice, index) => ({
              ...choice,
              value: !quillIsEmpty(choice.value)
                ? choice.value
                : {
                  ops: [{ insert: lang.exerciseForm.option + ` ${index + 1}` }],
                },
            })) ?? []
          }
          {...exerciseData}
          noCard
        />
      </div>
    );
  };

  return (
    <div
      onClick={(event) => event.stopPropagation()}
    >
      <Modal
        open={open}
        close={_close}
        header={lang.exercises.previewExercise}
        actions={getActions()}
        className={classes.modal}
        center
        transition
        large
      >
        {getContent()}
      </Modal>
    </div>
  );
};

PreviewExerciseModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  templateExerciseId: PropTypes.string,
  refresh: PropTypes.func,
  exerciseProps: PropTypes.object,
};

export default PreviewExerciseModal;
