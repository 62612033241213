import React, { useMemo, useState } from 'react';
import { Col, Row } from '@intuitivo/outline';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';

import { DRAG_DROP, DROPDOWN } from 'constants/exerciseOptions';
import { CAPTION, CHOICES, CONNECTING, FILLING, IMAGE, INFORMATION, ORDERING, PAUSE, SEGMENTATION, TABLE, TEXT, TRUEFALSE } from 'constants/exerciseTypes';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';
import { quillIsEmpty, renderGap, renderQuill } from 'utils';

import AskForExtraGaps from '../flow/ask-for-extra-gaps/AskForExtraGaps';
import AskForTable from '../flow/ask-for-table/AskForTable';
import AskForCaptions from '../flow/AskForCaptions';
import AskForChoices from '../flow/AskForChoices';
import AskForConnections from '../flow/AskForConnections';
import AskForExerciseCodes from '../flow/AskForExerciseCodes';
import AskForExerciseSettings from '../flow/AskForExerciseSettings';
import AskForExtraText from '../flow/AskForExtraText';
import AskForGapOptions from '../flow/AskForGapOptions';
import AskForGaps from '../flow/AskForGaps';
import AskForGapsDropdown from '../flow/AskForGapsDropdown';
import AskForGradingOption from '../flow/AskForGradingOption';
import AskForIdentifiers from '../flow/AskForIdentifiers';
import AskForImage from '../flow/AskForImage';
import AskForJustification from '../flow/AskForJustification';
import AskForModelAnswer from '../flow/AskForModelAnswer';
import AskForOrderGaps from '../flow/AskForOrderGaps';
import AskForOrderItems from '../flow/AskForOrderItems';
import AskForSegments from '../flow/AskForSegments';
import AskForShortAnswer from '../flow/AskForShortAnswer';
import AskForStatement from '../flow/AskForStatement';
import AskForTruth from '../flow/AskForTruth';
import PreviewExercise from '../flow/PreviewExercise';
import PlansPill from 'components/common/plans/PlansPill';

import useStyles from './styles';

const ExerciseForm = ({ type, statement, setStatement, enableMathSymbols, setEnableMathSymbols, mathSymbols, setMathSymbols, shuffleChoices, setShuffleChoices, choices, previewChoices, setChoices, gaps, setGaps, orderItems, setOrderItems, correctTrueFalse, setCorrectTrueFalse, edit, hasJustification, setHasJustification, isShortAnswer, setIsShortAnswer, image, setImage, characterLimit, setCharacterLimit, option, setOption, extraText, setExtraText, extraTextStartExpanded, setExtraTextStartExpanded, connectors, setConnectors, connections, setConnections, exerciseSettings, setExerciseSettings, modelAnswer, setModelAnswer, exerciseCells, tableDispatcher, gradingOption, setGradingOption, hasWordCount, setHasWordCount, restricted, exerciseCodes }) => {
  const classes = useStyles();
  const shortTextAnswerToggle = useFeature(toggles.shortTextAnswer);
  const ftgExtraToggle = useFeature(toggles.ftgExtra);
  const captionExtraToggle = useFeature(toggles.captionExtra);
  const ftgDropdownToggle = useFeature(toggles.ftgDropdown);
  const captionDropdownToggle = useFeature(toggles.captionDropdown);
  const ftgWriteToggle = useFeature(toggles.ftgWrite);
  const textAfterItemToggle = useFeature(toggles.textAfterItem);
  const exportIdentifiersToggle = useFeature(toggles.exportIdentifiers);
  const connectingToggle = useFeature(toggles.connecting);
  const segmentationToggle = useFeature(toggles.segmentation);
  const modelAnswerToggle = useFeature(toggles.modelAnswer);
  const imageConsistencyToggle = useFeature(toggles.imageConsistency);
  const correctionCodesToggle = useFeature(toggles.correctionCodes);

  const [orderGaps, setOrderGaps] = useState(false);

  let number = 1;
  const getExtraStep = () => {
    if (type === CHOICES) {
      return (
        <AskForChoices
          number={++number}
          shuffleChoices={shuffleChoices}
          setShuffleChoices={setShuffleChoices}
          choices={choices}
          setChoices={setChoices}
          restricted={restricted}
        />
      );
    }

    if (type === TRUEFALSE) {
      return (
        <AskForTruth
          number={++number}
          answer={correctTrueFalse}
          setAnswer={setCorrectTrueFalse}
        />
      );
    }

    if (type === FILLING) {
      const canShowGapOptions = ftgDropdownToggle || ftgWriteToggle;
      const canShowDropdownGaps = option === DROPDOWN;
      const canShowExtraGaps = ftgExtraToggle && (option === null || option === DRAG_DROP);
      const canShowOrderGaps = option === DRAG_DROP && orderGaps && gaps.length >= 2;

      return (
        <>
          {!restricted && (
            <AskForGaps
              number={++number}
              statement={statement}
              setStatement={setStatement}
              gaps={gaps}
              setGaps={setGaps}
              orderGaps={orderGaps}
              setOrderGaps={setOrderGaps}
            />
          )}
          {canShowGapOptions && !restricted && (
            <AskForGapOptions
              type={type}
              number={canShowGapOptions && ++number}
              option={option}
              setOption={setOption}
              setGaps={setGaps}
              mathSymbols={mathSymbols}
              setMathSymbols={setMathSymbols}
              enableMathSymbols={enableMathSymbols}
              setEnableMathSymbols={setEnableMathSymbols}
            />
          )}
          {canShowDropdownGaps && (
            <AskForGapsDropdown
              number={canShowDropdownGaps && ++number}
              gaps={gaps}
              setGaps={setGaps}
              type={type}
              restricted={restricted}
            />
          )}
          {canShowExtraGaps && (
            <AskForExtraGaps
              number={canShowExtraGaps && ++number}
              gaps={gaps}
              setGaps={setGaps}
              type={type}
              restricted={restricted}
            />
          )}
          {canShowOrderGaps && (
            <AskForOrderGaps
              type={type}
              number={canShowOrderGaps && ++number}
              gaps={gaps}
              setGaps={setGaps}
            />
          )}
        </>
      );
    }

    if (type === ORDERING) {
      return (
        <AskForOrderItems
          number={++number}
          orderItems={orderItems}
          setOrderItems={setOrderItems}
          restricted={restricted}
        />
      );
    }

    if (type === CAPTION) {
      const canShowCaptions = !!image;
      const canShowGapOptions = captionDropdownToggle && image;
      const canShowDropdownGaps = option === 'dropdown' && image;
      const canShowExtraGaps = captionExtraToggle && image && (option === null || option === 'dragDrop');

      return (
        <>
          {!restricted &&
            <AskForImage
              number={++number}
              image={image}
              setImage={setImage}
            />
          }
          {canShowCaptions && (
            <AskForCaptions
              number={canShowCaptions && ++number}
              image={image}
              gaps={gaps}
              setGaps={setGaps}
              exerciseId={v4()}
              ready={true}
              restricted={restricted}
            />
          )}
          {!restricted && canShowGapOptions && (
            <AskForGapOptions
              type={type}
              number={canShowGapOptions && ++number}
              option={option}
              setOption={setOption}
              setGaps={setGaps}
            />
          )}
          {canShowDropdownGaps && (
            <AskForGapsDropdown
              number={canShowDropdownGaps && ++number}
              gaps={gaps}
              setGaps={setGaps}
              type={type}
              restricted={restricted}
            />
          )}
          {canShowExtraGaps && (
            <AskForExtraGaps
              number={canShowExtraGaps && ++number}
              gaps={gaps}
              setGaps={setGaps}
              type={type}
              restricted={restricted}
            />
          )}
        </>
      );
    }

    if (type === TEXT && shortTextAnswerToggle) {
      return (
        <AskForShortAnswer
          number={++number}
          isShortAnswer={isShortAnswer}
          setIsShortAnswer={setIsShortAnswer}
          characterLimit={characterLimit}
          setCharacterLimit={setCharacterLimit}
          enableMathSymbols={enableMathSymbols}
          setEnableMathSymbols={setEnableMathSymbols}
          mathSymbols={mathSymbols}
          setMathSymbols={setMathSymbols}
          hasWordCount={hasWordCount}
          setHasWordCount={setHasWordCount}
          restricted={restricted}
        />
      );
    }

    if (type === CONNECTING) {
      return (
        <AskForConnections
          number={++number}
          connectors={connectors}
          setConnectors={setConnectors}
          connections={connections}
          setConnections={setConnections}
          setShuffleConnectors={setShuffleChoices}
          shuffleConnectors={shuffleChoices}
          restricted={restricted}
        />
      );
    }

    if (type === SEGMENTATION) {
      const canShowSegments = !!image;
      return (
        <>
          {!restricted && (
            <AskForImage
              number={++number}
              image={image}
              setImage={setImage}
            />
          )}
          {canShowSegments && (
            <AskForSegments
              number={++number}
              connectors={connectors}
              setConnectors={setConnectors}
              connections={connections}
              setConnections={setConnections}
              image={image}
              option={option}
              setOption={setOption}
              exerciseId={v4()}
              restricted={restricted}
            />
          )}
        </>
      );
    }

    if (type === TABLE) {
      return (
        <>
          <AskForTable
            number={++number}
            exerciseCells={exerciseCells}
            tableDispatcher={tableDispatcher}
            restricted={restricted}
          />
          <AskForGradingOption
            number={++number}
            gradingOption={gradingOption}
            setGradingOption={setGradingOption}
          />
        </>
      );
    }
  };

  const getJustificationStep = () => {
    if (type === CHOICES || type === TRUEFALSE) {
      return (
        <AskForJustification
          number={++number}
          hasJustification={hasJustification}
          setHasJustification={setHasJustification}
          enableMathSymbols={enableMathSymbols}
          setEnableMathSymbols={setEnableMathSymbols}
          mathSymbols={mathSymbols}
          setMathSymbols={setMathSymbols}
          setModelAnswer={setModelAnswer}
          hasWordCount={hasWordCount}
          setHasWordCount={setHasWordCount}
        />
      );
    }
  };

  const identifierItems = useMemo(() => {
    if (type === CHOICES) {
      return choices.map((choice, index) => ({
        ...choice,
        value: quillIsEmpty(choice.value) ? lang.exerciseForm.option + ' ' + (index + 1) : renderQuill(choice.value),
      }));
    }

    if ([FILLING, CAPTION].includes(type)) {
      return gaps.map((gap) => ({
        ...gap,
        value: renderGap(gap),
      }));
    }

    if (type === ORDERING) {
      return orderItems.map((item, index) => ({
        ...item,
        value: quillIsEmpty(item.text) ? lang.exerciseForm.item + ' ' + (index + 1) : renderQuill(item.text),
      }));
    }

    if ([CONNECTING, SEGMENTATION].includes(type)) {
      return connectors.map((connector, index) => ({
        ...connector,
        value: quillIsEmpty(connector.value) ? lang.exerciseForm.connecting.connector + ' ' + (index + 1) : renderQuill(connector.value),
      }));
    }
    if (type === TABLE) {
      const updatedExerciseCells = exerciseCells.map(cell => {
        if (cell.col !== 0 && cell.row !== 0 && !cell.originalIdentifier) {
          return {
            ...cell,
            originalIdentifier: `${String.fromCharCode(65 + cell.col)}_${cell.row}`,
          };
        }
        return cell;
      });

      return updatedExerciseCells
        .filter(cell => cell.originalIdentifier)
        .sort((a, b) => a.originalIdentifier.localeCompare(b.originalIdentifier))
        .map(cell => ({
          ...cell,
          value: cell.originalIdentifier,
        }));
    }
  }, [type, choices, gaps, orderItems, connectors, exerciseCells]);

  const setIdentifierItems = useMemo(() => {
    if (type === CHOICES) {
      return setChoices;
    }

    if ([FILLING, CAPTION].includes(type)) {
      return setGaps;
    }

    if (type === ORDERING) {
      return setOrderItems;
    }

    if ([CONNECTING, SEGMENTATION].includes(type)) {
      return setConnectors;
    }
  }, [type, setChoices, setGaps, setOrderItems, setConnectors]);

  /**
 * TODO: Remove oldExerciseForm after the Image Consistency epic is fully implemented
 * https://intuitivo.atlassian.net/browse/DK-2579
 */
  const oldExerciseForm = !imageConsistencyToggle && (
    <Row className={classes.exerciseFormContainerOld}>
      {((type === CONNECTING && !connectingToggle) || (type === SEGMENTATION && !segmentationToggle)) && edit && (
        <div className={classes.premiumPill}>
          <PlansPill
            tip={lang.plans.warningCreatePremiumExercise}
          />
        </div>
      )}
      <Col xl={edit ? 12 : 6} sm={12}>
        <AskForStatement
          number={number}
          statement={statement}
          setStatement={setStatement}
          setGaps={setGaps}
          type={type}
        />
        {getExtraStep()}
        {correctionCodesToggle && [CHOICES, TRUEFALSE, FILLING, CAPTION, ORDERING, CONNECTING, SEGMENTATION, TABLE].includes(type) &&
          <AskForExerciseCodes
            number={++number}
            exerciseCodes={exerciseCodes}
          />}
        {getJustificationStep()}
        {textAfterItemToggle && type !== PAUSE && type !== INFORMATION && (
          <AskForExtraText
            number={textAfterItemToggle && ++number}
            extraText={extraText}
            setExtraText={setExtraText}
            extraTextStartExpanded={extraTextStartExpanded}
            setExtraTextStartExpanded={setExtraTextStartExpanded}
          />
        )}
        {modelAnswerToggle && ([TEXT, IMAGE].includes(type) || ([CHOICES, TRUEFALSE].includes(type) && hasJustification)) && (
          <AskForModelAnswer
            number={modelAnswerToggle && ++number}
            modelAnswer={modelAnswer}
            setModelAnswer={setModelAnswer}
          />
        )}
      </Col>
      <Col xl={edit ? 12 : 6} sm={12}>
        {!edit && (
          <>
            <PreviewExercise
              statement={statement}
              type={type}
              choices={previewChoices}
              gaps={gaps}
              exerciseCells={exerciseCells}
              orderItems={orderItems}
              correctTrueFalse={correctTrueFalse}
              hasJustification={hasJustification}
              setHasJustification={setHasJustification}
              image={image}
              isShortAnswer={isShortAnswer}
              option={option}
              extraText={extraText}
              extraTextStartExpanded={extraTextStartExpanded}
              connectors={connectors}
              connections={connections}
              enableMathSymbols={enableMathSymbols}
              modelAnswer={modelAnswer}
              gradingOption={gradingOption}
              hasWordCount={hasWordCount}
            />
            <AskForExerciseSettings
              exerciseSettings={exerciseSettings}
              setExerciseSettings={setExerciseSettings}
            />
          </>
        )}
        {exportIdentifiersToggle && [CHOICES, FILLING, CAPTION, ORDERING, CONNECTING, SEGMENTATION, TABLE].includes(type) && (
          <AskForIdentifiers
            items={identifierItems}
            setItems={setIdentifierItems}
            type={type}
            tableDispatcher={tableDispatcher}
          />
        )}
      </Col>
    </Row>
  );

  return (
    <>
      {oldExerciseForm}
      {imageConsistencyToggle && (
        <div className={classes.exerciseFormContainer}>
          {((type === CONNECTING && !connectingToggle) || (type === SEGMENTATION && !segmentationToggle)) && edit && (
            <div className={classes.premiumPill}>
              <PlansPill
                tip={lang.plans.warningCreatePremiumExercise}
              />
            </div>
          )}
          <AskForStatement
            number={number}
            statement={statement}
            setStatement={setStatement}
            setGaps={setGaps}
            type={type}
          />
          {getExtraStep()}
          {correctionCodesToggle && [CHOICES, TRUEFALSE, FILLING, CAPTION, ORDERING, CONNECTING, SEGMENTATION, TABLE].includes(type) &&
            <AskForExerciseCodes
              number={++number}
              exerciseCodes={exerciseCodes}
              exercise={{ statement, choices, gaps, option, orderItems, exerciseCells, type, image, connectors }}
            />}
          {getJustificationStep()}
          {textAfterItemToggle && type !== PAUSE && type !== INFORMATION && (
            <AskForExtraText
              number={textAfterItemToggle && ++number}
              extraText={extraText}
              setExtraText={setExtraText}
              extraTextStartExpanded={extraTextStartExpanded}
              setExtraTextStartExpanded={setExtraTextStartExpanded}
            />
          )}
          {modelAnswerToggle && ([TEXT, IMAGE].includes(type) || ([CHOICES, TRUEFALSE].includes(type) && hasJustification)) && (
            <AskForModelAnswer
              number={modelAnswerToggle && ++number}
              modelAnswer={modelAnswer}
              setModelAnswer={setModelAnswer}
            />
          )}
          {exportIdentifiersToggle && [CHOICES, FILLING, CAPTION, ORDERING, CONNECTING, SEGMENTATION, TABLE].includes(type) && (
            <AskForIdentifiers
              number={++number}
              items={identifierItems}
              setItems={setIdentifierItems}
              type={type}
              tableDispatcher={tableDispatcher}
            />
          )}
        </div>
      )}
    </>
  );
};

ExerciseForm.propTypes = {
  type: PropTypes.string,
  statement: PropTypes.object,
  setStatement: PropTypes.func,
  shuffleChoices: PropTypes.bool,
  setShuffleChoices: PropTypes.func,
  choices: PropTypes.array,
  previewChoices: PropTypes.array,
  setChoices: PropTypes.func,
  gaps: PropTypes.array,
  setGaps: PropTypes.func,
  orderItems: PropTypes.array,
  setOrderItems: PropTypes.func,
  correctTrueFalse: PropTypes.bool,
  setCorrectTrueFalse: PropTypes.func,
  edit: PropTypes.bool,
  hasJustification: PropTypes.bool,
  setHasJustification: PropTypes.func,
  image: PropTypes.array,
  setImage: PropTypes.func,
  isShortAnswer: PropTypes.bool,
  setIsShortAnswer: PropTypes.func,
  characterLimit: PropTypes.bool,
  setCharacterLimit: PropTypes.func,
  option: PropTypes.string,
  setOption: PropTypes.func,
  gradingOption: PropTypes.string,
  setGradingOption: PropTypes.func,
  extraText: PropTypes.object,
  setExtraText: PropTypes.func,
  extraTextStartExpanded: PropTypes.bool,
  setExtraTextStartExpanded: PropTypes.func,
  connectors: PropTypes.array,
  setConnectors: PropTypes.func,
  connections: PropTypes.array,
  setConnections: PropTypes.func,
  enableMathSymbols: PropTypes.bool,
  setEnableMathSymbols: PropTypes.func,
  mathSymbols: PropTypes.object,
  setMathSymbols: PropTypes.func,
  exerciseSettings: PropTypes.object,
  setExerciseSettings: PropTypes.func,
  modelAnswer: PropTypes.object,
  setModelAnswer: PropTypes.func,
  exerciseCells: PropTypes.array,
  tableDispatcher: PropTypes.func,
  hasWordCount: PropTypes.bool,
  setHasWordCount: PropTypes.func,
  restricted: PropTypes.bool,
  exerciseCodes: PropTypes.array,
};

export default ExerciseForm;
