import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  overlay: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#00000000',
    zIndex: '1000',
  },
  exerciseCard: {
    maxWidth: 'calc(1200px + 126px) !important',
    maxHeight: '80vh',
    backgroundColor: theme.white,
    padding: '45px 63px',
    border: 'none',
    borderRadius: '35px',
    position: 'relative',
    overflow: 'auto',
    width: 'calc(100% - 40px) !important',
    margin: '20px',
  },
  exerciseContent: {
    backgroundColor: theme.white,
    borderRadius: '2px',
    maxHeight: '90vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  exerciseContentInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px 20px 10px 20px',
    gap: '27px',
    flexWrap: 'wrap',
    marginBottom: '10px',
  },
  exerciseType: {
    borderRadius: '100000px',
    padding: '7px 10px',
    color: theme.exerciseColor,
    backgroundColor: theme.exerciseColorTransparency,
    fontSize: '14px',
  },
  exerciseUsages: {
    fontSize: '14px',
    color: theme.exerciseColor,
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
  },
  exerciseUsagesIcon: {
    marginRight: '5px',
  },
  closeButton: {
    color: theme.accentColor,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: '21px',
    top: '23px',
    marginLeft: 'auto',
  },
  closeButtonIcon: {
    opacity: '50%',
    transform: 'scale(1.5)',
    '&:hover': {
      opacity: '100%',
    },
  },
  useButton: {
    width: '78px',
    height: '65px',
    borderRadius: '35px 0 35px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'absolute',
    paddingTop: '0.5em',
    left: '0px',
    top: '0px',
    gap: '1em',
    backgroundColor: theme.exerciseColor,
    '&:hover': {
      backgroundColor: '#1B8866',
    },
    '&:focus': {
      backgroundColor: theme.exerciseColor,
    },
  },
  icon: {
    transform: 'scale(1.80)',
  },
  exerciseTitle: {
    fontWeight: 600,
    fontSize: '36px',
    textAlign: 'center',
    marginTop: '10px',
  },
  exerciseAuthor: {
    display: 'flex',
    alignItems: 'center',
    color: theme.white,
    backgroundColor: theme.exerciseColor,
    borderRadius: '100000px',
    padding: '5px 10px',
    font: '14px',
    minHeight: '28px',
  },
  copyContainer: {
    padding: '20px 20px 20px 20px',
  },
  copyInformation: {
    marginBottom: '10px',
  },
  copyActions: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '10px',
  },
  copyAction: {
    borderRadius: '4px',
  },
  filters: {
    marginBottom: '20px',
    maxHeight: '45%',
    overflow: 'hidden',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.25em',
    justifyContent: 'center',
  },
  [`@media (max-width: ${theme.md}px)`]: {
    exerciseTitle: {
      marginTop: '20px',
    },
    exerciseCard: {
      padding: '45px 20px',
    },
  },
}));
