import { createUseStyles } from 'react-jss';

export default createUseStyles({
  attemptExercise: {
    marginBottom: '20px',
  },
  exercisesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
  },
});
