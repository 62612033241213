import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  section: {
    '&.answerable': {
      userSelect: 'none',
    },
    width: 'unset !important',
  },
  sectionDescription: {
    '& > p': {
      margin: '0',
    },
  },
  exercisesWrapper: {
    gap: '1em',
    padding: '20px 10px',
  },
  loading: {
    width: '1120px',
  },
  icon: {
    fontSize: '75px',
  },
  lockedSection: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '10px',
  },
  [`@media (max-width: ${theme.xl}px)`]: {
    loading: {
      width: '920px',
    },
  },
  [`@media (max-width: ${theme.lg}px)`]: {
    loading: {
      width: '710px',
    },
  },
  [`@media (max-width: ${theme.md}px)`]: {
    loading: {
      width: '490px',
    },
  },
  [`@media (max-width: ${theme.sm}px)`]: {
    loading: {
      width: '530px',
    },
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    loading: {
      width: '100%',
    },
    section: {
      width: 'calc(100% + 20px) !important',
    },
  },
}));
